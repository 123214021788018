import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import useData from "../../Context/useData";
import { baseUrl } from "../../helper";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";

const BalanceSend = () => {
  const { token } = useData().user;
  const [selectUser, setSelectUser] = useState(null);
  const [loader, setLoader] = useState(false);

  const [subAdminList, setSubAdminList] = useState([]);
  const [filterUser, setFilterUser] = useState("");

  console.log("filter", filterUser);

  // get all user
  useEffect(() => {
    if (!token) {
      return;
    }
    const getProfile = async () => {
      console.log("col11---");
      setLoader(true);
      try {
        const res = await fetch(
          `${baseUrl.url}/all/user?role_id=3${
            filterUser && `&login_id=${filterUser}`
          }`,
          {
            method: "Get",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const resData = await res.json();
        if (res.ok) {
          setSubAdminList(resData?.data?.data);
        }
      } catch (e) {
        console.error(e.message);
      } finally {
        setLoader(false);
      }
    };
    getProfile().then();
  }, [token, filterUser]);

  const adminId = subAdminList?.map((user) => {
    let obj = {};
    obj.label = user?.login_id;
    obj.value = user?.id;
    return obj;
  });

  const handleBalanceSend = (e) => {
    e.preventDefault();
    if (!selectUser && !token) {
      return;
    }
    const form = e.target;
    const amount = form.amount.value;
    if (!amount) {
      return toast.warn("Please enter amount");
    }
    if (!selectUser) {
      return toast.warn("Please select a user");
    }
    const postInfo = {
      amount,
      sub_admin_id: selectUser,
    };

    let formData = new FormData();
    for (const i in postInfo) {
      const element = postInfo[i];
      formData.append(`${i}`, element);
    }

    const postData = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/balance/send`, {
          method: "post",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        const resData = await res.json();
        if (res.ok) {
          form.reset();
          setSelectUser(null);
          setFilterUser("");
          toast.success(resData.message);
        } else {
          toast.warn(resData.message);
        }
      } catch (error) {
        console.error(error.message);
      }
    };
    postData();
  };

  const filterTem = () => {
    return (
      <div>
        <input
          type="text"
          className="border w-full py-2 px-4 rounded"
          placeholder="Select Student"
          value={filterUser}
          onChange={(e) => setFilterUser(e.target.value)}
        />
      </div>
    );
  };

  if (loader) {
    return <Loader />;
  }

  return (
    <div className="pt-10 px-4">
      <p className="text-2xl">Balance Transfer in Sub Admin </p>
      <form onSubmit={handleBalanceSend} className="pt-5">
        <div className="grid grid-cols-2 gap-x-4">
          <div className="flex flex-col w-full">
            <label className="pb-1 ps-2">Amount</label>
            <input
              type="text"
              className="border w-full py-2 px-4 rounded"
              placeholder="Amount"
              name="amount"
            />
          </div>

          <div className="flex flex-col common">
            <label className="pb-1 ps-2">Select Admin</label>
            <Dropdown
              value={selectUser}
              onChange={(e) => setSelectUser(e.value)}
              options={adminId}
              optionLabel="label"
              placeholder="User Id"
              filter
              filterTemplate={filterTem}
              className="w-full"
            />
          </div>
        </div>

        <input
          className="border mt-4 py-2 px-6 bg-green-500 text-white rounded cursor-pointer"
          type="submit"
          value="Submit"
        />
      </form>
    </div>
  );
};

export default BalanceSend;
