import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import useData from "../../Context/useData";
import { baseUrl } from "../../helper";
// import { MdTry } from "react-icons/md";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";

const WithdrawReq = () => {
  const { token } = useData().user;
  const [loader, setLoader] = useState(false);
  const [newData, setNewData] = useState("");

  // student_refer

  useEffect(() => {
    if (!token) {
      return;
    }

    const getWithdrawList = async () => {
      setLoader(true);
      try {
        const res = await fetch(`${baseUrl.url}/admin/withdraw/request-list`, {
          method: "Get",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setNewData(resData.data);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoader(false);
      }
    };
    getWithdrawList();
  }, [token]);

  if (loader) {
    return <Loader />;
  }

  console.log("newDAta-->", newData);

  return (
    <section className="py-10 px-4">
      <div className="card">
        hi
        <DataTable value={[]} tableStyle={{ minWidth: "50rem" }}>
          <Column field="teamAChildCount" header="Team A"></Column>
          <Column field="teamBChildCount" header="Team B"></Column>
          <Column field="total_similer_matches" header="Total Match"></Column>
          <Column field="total_odd_matchs" header="Total unmatched"></Column>
        </DataTable>
      </div>
    </section>
  );
};

export default WithdrawReq;
