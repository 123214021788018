import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import useData from "../../Context/useData";
import AssignStudent from "./AssignStudent";
import { baseUrl } from "../../helper";
import { toast } from "react-toastify";

const StudentBatchPage = () => {
  const {
    allUsers,
    token,
    allUserPageNumber,
    setAllUserPageNumber,
    totalAllUser,
    allUserLoader,
    allUserLimit,
    setAllUserLimit,
    searchUserId,
    setSearchUserId,
  } = useData().user;

  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showAssign, setShowAssign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allBatch, setAllBatch] = useState(null);
  const modalAssign = { showAssign, setShowAssign, allBatch };

  // get all batch
  useEffect(() => {
    if (!token) {
      return;
    }
    const getCourse = async () => {
      setLoading(true);
      try {
        const res = await fetch(`${baseUrl.url}/batch`, {
          method: "Get",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setAllBatch(resData?.data?.data);
          // setTotalData(resData?.data?.total);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    getCourse().then();
  }, [token]);

  // Table Template
  const UserIdTem = (data) => {
    return <p>{data?.login_id}</p>;
  };

  const UserNameTem = (data) => {
    return <p>{data.name}</p>;
  };

  const UserEmailTem = (data) => {
    return <p>{data.email}</p>;
  };

  const UserMobileTem = (data) => {
    return <p>{data.mobile}</p>;
  };
  // console.log("allUserPageNumber", allUserPageNumber);
  return (
    <div className="ps-4 py-10">
      <div className="card">
        <div className="pb-3 flex justify-between items-center">
          <h4 className="text-2xl font-medium">Create Student Batch</h4>
          <div className="flex justify-between items-center gap-x-4">
            {selectedStudent?.length > 0 && (
              <Button
                icon="pi pi-plus"
                severity="success"
                size="small"
                label="Assign Student"
                onClick={() => setShowAssign(true)}
              />
            )}
          </div>
        </div>

        <DataTable
          value={allUsers}
          first={allUserPageNumber}
          paginator
          rows={allUserLimit}
          totalRecords={totalAllUser}
          loading={allUserLoader || loading}
          rowHover={true}
          filterDisplay="row"
          lazy
          selection={selectedStudent}
          onSelectionChange={(e) => setSelectedStudent(e.value)}
          dataKey="id"
          onPage={(e) => {
            console.log("changePage", e);
            setAllUserPageNumber(e.first);
            setAllUserLimit(e.rows);
          }}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
          ></Column>
          <Column
            field="phone"
            header="Student Id"
            filter
            body={UserIdTem}
            filterElement={
              <InputText
                className={"p-input text-sm"}
                type="text"
                name="name"
                value={searchUserId}
                onChange={(e) => setSearchUserId(e.target.value)}
                placeholder="Filter by User Id"
              />
            }
            style={{ minWidth: "200px", maxWidth: "120px" }}
          ></Column>
          <Column header="Student Name" body={UserNameTem}></Column>
          <Column header="Email" body={UserEmailTem}></Column>
          <Column body={UserMobileTem} header="Phone"></Column>
        </DataTable>
      </div>
      {
        <AssignStudent
          modalAssign={modalAssign}
          // allBatch={allBatch}
          selectedStudent={selectedStudent}
          setSelectedStudent={setSelectedStudent}
        />
      }
    </div>
  );
};

export default StudentBatchPage;
