import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import useData from "../../Context/useData";
import { baseUrl } from "../../helper";
import { toast } from "react-toastify";
import moment from "moment";
import { Button } from "primereact/button";
import Loader from "../Loader/Loader";

const CreateEvent = () => {
  const { token } = useData().user;
  const [allEvents, setAllEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [firstDate, setFirstDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [selectDesignation, setSelectDesignation] = useState(null);
  const [loader, setLoader] = useState(false);
  const [allDesignation, setAllDesignation] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [update, setUpdate] = useState(false);

  console.log("modeldata", modalData);

  useEffect(() => {
    if (!token) {
      return;
    }
    const getUser = async () => {
      setLoader(true);
      try {
        const res = await fetch(`${baseUrl.url}/designation`, {
          method: "Get",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setAllDesignation(resData.data);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoader(false);
      }
    };
    getUser();
  }, [token]);

  useEffect(() => {
    if (!token) {
      return;
    }
    const getEvent = async () => {
      setLoader(true);
      try {
        const res = await fetch(`${baseUrl.url}/event`, {
          method: "Get",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setAllEvents(resData?.data);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoader(false);
      }
    };
    getEvent();
  }, [token, update]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const enrollNumber = form.enrollNumber.value;
    const start_time = moment(firstDate).format("YYYY-MM-DD");
    console.log("first", start_time);
    const end_time = moment(lastDate).format("YYYY-MM-DD");
    const designation_id = selectDesignation.id;
    const amount = form.amount.value;

    const postData = {
      title: enrollNumber,
      start_time,
      end_time,
      designation_id,
      amount,
    };
    const postAdmin = async () => {
      setLoader(true);
      try {
        const res = await fetch(`${baseUrl.url}/event`, {
          method: "post",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(postData),
        });

        const resData = await res.json();
        if (res.ok) {
          toast.success(resData.message);
          setSelectDesignation(null);
          form.reset();
          setShowModal(false);
          setUpdate(!update);
        } else {
          toast.error(resData.message);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoader(false);
      }
    };
    postAdmin();
  };

  const handleDelete = async (id) => {
    try {
      const res = await fetch(`${baseUrl.url}/event/${id}`, {
        method: "delete",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const resData = await res.json();
      if (res.ok) {
      } else {
        toast.error(resData.message);
        setUpdate(!update);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoader(false);
    }
  };

  const firstDateTem = (data) => {
    const firstDate = moment(data?.start_time).format("DD-MM-YY");
    return <p>{firstDate}</p>;
  };

  const lastDateTem = (data) => {
    const firstDate = moment(data?.end_time).format("DD-MM-YY");
    return <p>{firstDate}</p>;
  };

  const targetEnroll = (data) => {
    return <p>{data?.title}</p>;
  };

  const designationTem = (data) => {
    return <p>{data?.designation?.name}</p>;
  };

  const ActionTemplate = (data) => {
    const id = data?.id;

    return (
      <div className="flex gap-2 items-center">
        <Button
          type="button"
          tooltip="Delete"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-trash"
          severity="danger"
          className="h-2rem w-2rem"
          onClick={() => handleDelete(id)}
        />
        <Button
          type="button"
          tooltip="Edit"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="h-2rem w-2rem mr-2"
          onClick={() => {
            setShowModal(!showModal);
            setModalData(data);
          }}
        ></Button>
      </div>
    );
  };

  if (loader) {
    return <Loader />;
  }

  return (
    <section className="py-10 px-4">
      <div className="flex justify-between items-center mb-3">
        <h3 className="text-lg">All Events</h3>

        <div>
          <button
            onClick={() => setShowModal(true)}
            className="px-4 py-2 bg-green-600 text-white rounded"
          >
            Event
          </button>
        </div>

        {/* <Button label="Show" icon="pi pi-external-link"  /> */}
      </div>

      <div className="card">
        <DataTable
          emptyMessage="No Event Data Fount"
          value={allEvents}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            field="code"
            header="First Date"
            body={firstDateTem}
            sortable
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="name"
            header="Last Date"
            sortable
            body={lastDateTem}
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="category"
            header="Target"
            sortable
            body={targetEnroll}
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="quantity"
            header="Designation"
            body={designationTem}
            sortable
            style={{ width: "25%" }}
          ></Column>
          <Column
            body={ActionTemplate}
            headerStyle={{
              width: "6rem",
            }}
            bodyStyle={{
              textAlign: "center",
            }}
            header="Action"
          />
        </DataTable>
      </div>
      <Dialog
        header="Create New Event"
        visible={showModal}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setShowModal(false)}
        draggable={false}
        resizable={false}
      >
        <form onSubmit={handleSubmit} className="common">
          <div className="flex justify-between items-center gap-2">
            <Calendar
              value={firstDate}
              onChange={(e) => setFirstDate(e.value)}
              readOnlyInput
              placeholder="Event Start Date"
              className="w-full"
              dateFormat="mm/dd/yy"
            />

            <Calendar
              value={lastDate}
              onChange={(e) => setLastDate(e.value)}
              readOnlyInput
              placeholder="Event End Date"
              className="w-full"
              dateFormat="mm/dd/yy"
            />
          </div>

          <div className="mt-2 flex flex-col">
            <label className="pb-1">Select Designation</label>
            <Dropdown
              value={selectDesignation}
              onChange={(e) => setSelectDesignation(e.value)}
              options={allDesignation}
              optionLabel="name"
              placeholder="Select Designation"
              filter
              className="w-full"
            />
          </div>

          <div className="mt-2 flex flex-col">
            <label className="pb-1">Target Matching</label>
            <input
              type="text"
              className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
              placeholder="Amount Of Enrollment"
              name="enrollNumber"
            />
          </div>

          <div className="mt-2 flex flex-col">
            <label className="pb-1">Enter Amount of Event</label>
            <input
              type="text"
              className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
              placeholder="Amount"
              name="amount"
            />
          </div>

          <input
            className="border mt-4 py-2 px-6 bg-green-500 text-white rounded cursor-pointer"
            type="submit"
            value="Submit"
          />
        </form>
      </Dialog>
    </section>
  );
};

export default CreateEvent;
