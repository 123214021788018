import React, { useEffect, useState } from "react";
import useData from "../../Context/useData";
import { baseUrl } from "../../helper";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdDoneAll } from "react-icons/io";
import moment from "moment";

const AdminWithdrawList = () => {
  const { token } = useData().user;
  const [adminWithdrawList, setAdminWithdrawList] = useState(null);
  const [updateAdminList, setUpdateAdminList] = useState(false);
  const [limitData, setLimitData] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  let pages = pageNumber / limitData + 1;

  // student_refer
  useEffect(() => {
    if (!token) {
      return;
    }

    const getAllWithdrawList = async () => {
      setLoading(true);
      try {
        const res = await fetch(`${baseUrl.url}/admin/withdraw/request-list?page=${pages.toFixed(0)}`, {
          method: "Get",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setAdminWithdrawList(resData.data?.data);
          setTotalData(resData.data.total);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    getAllWithdrawList();
  }, [token, totalData, pageNumber, limitData, pages, updateAdminList]);
  const handleApprove = async (id) => {
    
    if (!id || !token) {
      return;
    }
    try {
      const res = await fetch(`${baseUrl.url}/withdraw/request-done/${id}`, {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        setUpdateAdminList(!updateAdminList);
        toast.success(resData.message);
      } else {
        const resData = await res.json();
        toast.error(resData.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const ActionTemplate = (data) => {
    return (
      <Menu
        direction={"right"}
        align={"center"}
        menuButton={
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>
        }
        transition
      >
        <MenuItem
          className="hover:text-green-500"
          onClick={() => handleApprove(data?.id)}
        >
          <span>
            <IoMdDoneAll className="mr-2" />
          </span>{" "}
          Approve
        </MenuItem>
      </Menu>
    );
  };

  const PaymenytType = (data)=>{
    return <p>{data.payment_type === "Bikash" ? "Bkash" : data.payment_type}</p>
  }

  const DateTemplate = (data) => {
    const formetDate = moment(data.updated_at).format("DD-MM-YY");
    return <p>{formetDate}</p>;
  };

  const StatusTemplate = (data) => {
    return <p>{data.status}</p>;
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <section className="py-10 px-4">
      <div className="card">
        <DataTable
          value={adminWithdrawList}
          emptyMessage="No Account found."
          size="small"
          first={pageNumber}
          paginator
          rows={limitData}
          totalRecords={totalData}
          loading={loading}
          rowHover={true}
          lazy
          onPage={(e) => {
            setPageNumber(e.first);
            setLimitData(e.rows);
          }}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column field="phone" header="Phone"></Column>
          <Column  body={PaymenytType} header="Payment"></Column>
          <Column field="amount" header="Amount"></Column>
          <Column field="code" header="Code"></Column>
          <Column body={DateTemplate} header="Date"></Column>
          <Column body={StatusTemplate} header="Status"></Column>
          <Column body={ActionTemplate} header="Action"></Column>
        </DataTable>
      </div>
    </section>
  );
};

export default AdminWithdrawList;
