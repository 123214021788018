import React from "react";
import MyAccount from "../../Components/MyAccount/MyAccount";
const Home = () => {
  return (
    <div className="raleway">
      <MyAccount />
    </div>
  );
};

export default Home;
