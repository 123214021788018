import { GoHome } from "react-icons/go";

export const baseUrl = {
  url: process.env.REACT_APP_DOMAIN,
  img: process.env.REACT_APP_IMAGE,
};

export const saveInCookie = (name, value) => {
  document.cookie = name + "=" + value;
};

export const getFromCookie = (name) => {
  const items = document.cookie.split(";");
  const item = items.find((item) => item.includes(name))?.split("=")[1];
  return item;
};

// export const removeToken = (arr) => {
//   if (arr?.length <= 0) {
//     return;
//   }
//   arr?.map((token) => {
//     return (document.cookie = `${token}=''`);
//   });
// };

export const removeToken = (name) => {
  if (!name) {
    return;
  }
  document.cookie = `${name}=''`;
};

export const handleDelete = async (
  id,
  endPoint,
  data,
  setData,
  toast,
  token
) => {
  console.log("token", token);
  const filteredData = data.filter((data) => data?.id !== id);
  try {
    const res = await fetch(`${baseUrl?.url}${endPoint}/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await res.json();
    if (res.ok) {
      setData(filteredData);
      console.log("success", responseData);
      // toast.error(responseData?.message)
    } else {
      // toast.error(responseData?.message)
      console.error(responseData?.message);
    }
  } catch (err) {
    // toast.error(err?.message)
    console.error(err?.message);
  }
};

export const district = [
  { label: "Dhaka", value: "Dhaka" },
  { label: "Bagerhat", value: "Bagerhat" },
  { label: "Bandarban", value: "Bandarban" },
  { label: "Barguna", value: "Barguna" },
  { label: "Barishal", value: "Barishal" },
  { label: "Bhola", value: "Bhola" },
  { label: "Bogra", value: "Bogra" },
  { label: "Brahmanbaria", value: "Brahmanbaria" },
  { label: "Chandpur", value: "Chandpur" },
  { label: "Chapai Nawabganj", value: "Chapai Nawabganj" },
  { label: "Chattogram (Chittagong)", value: "Chattogram (Chittagong)" },
  { label: "Chuadanga", value: "Chuadanga" },
  { label: "Cox's Bazar", value: "Cox's Bazar" },
  { label: "Cumilla (Comilla)", value: "Cumilla (Comilla)" },
  { label: "Dinajpur", value: "Dinajpur" },
  { label: "Faridpur", value: "Faridpur" },
  { label: "Feni", value: "Feni" },
  { label: "Gaibandha", value: "Gaibandha" },
  { label: "Gazipur", value: "Gazipur" },
  { label: "Gopalganj", value: "Gopalganj" },
  { label: "Habiganj", value: "Habiganj" },
  { label: "Jamalpur", value: "Jamalpur" },
  { label: "Jashore (Jessore)", value: "Jashore (Jessore)" },
  { label: "Jhalokati", value: "Jhalokati" },
  { label: "Jhenaidah", value: "Jhenaidah" },
  { label: "Joypurhat", value: "Joypurhat" },
  { label: "Khagrachari", value: "Khagrachari" },
  { label: "Khulna", value: "Khulna" },
  { label: "Kishoreganj", value: "Kishoreganj" },
  { label: "Kurigram", value: "Kurigram" },
  { label: "Kushtia", value: "Kushtia" },
  { label: "Lakshmipur", value: "Lakshmipur" },
  { label: "Lalmonirhat", value: "Lalmonirhat" },
  { label: "Madaripur", value: "Madaripur" },
  { label: "Magura", value: "Magura" },
  { label: "Manikganj", value: "Manikganj" },
  { label: "Meherpur", value: "Meherpur" },
  { label: "Moulvibazar", value: "Moulvibazar" },
  { label: "Munshiganj", value: "Munshiganj" },
  { label: "Mymensingh", value: "Mymensingh" },
  { label: "Naogaon", value: "Naogaon" },
  { label: "Narail", value: "Narail" },
  { label: "Narayanganj", value: "Narayanganj" },
  { label: "Narsingdi", value: "Narsingdi" },
  { label: "Natore", value: "Natore" },
  { label: "Netrokona", value: "Netrokona" },
  { label: "Nilphamari", value: "Nilphamari" },
  { label: "Noakhali", value: "Noakhali" },
  { label: "Pabna", value: "Pabna" },
  { label: "Panchagarh", value: "Panchagarh" },
  { label: "Patuakhali", value: "Patuakhali" },
  { label: "Pirojpur", value: "Pirojpur" },
  { label: "Rajbari", value: "Rajbari" },
  { label: "Rajshahi", value: "Rajshahi" },
  { label: "Rangamati", value: "Rangamati" },
  { label: "Rangpur", value: "Rangpur" },
  { label: "Satkhira", value: "Satkhira" },
  { label: "Shariatpur", value: "Shariatpur" },
  { label: "Sherpur", value: "Sherpur" },
  { label: "Sirajganj", value: "Sirajganj" },
  { label: "Sunamganj", value: "Sunamganj" },
  { label: "Sylhet", value: "Sylhet" },
  { label: "Tangail", value: "Tangail" },
  { label: "Thakurgaon", value: "Thakurgaon" },
];

export const countries = [{ label: "Bangladesh", value: "Bangladesh" }];

export const showDummyImage = ({ currentTarget }, imageSize) => {
  currentTarget.onerror = null;
  currentTarget.src = `https://dummyimage.com/${imageSize}`;
};

export const sidebarItems = [
  {
    id: 1,
    title: "Home",
    icon: <GoHome size={20} />,
    to: "/",
  },
];
