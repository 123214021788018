import React from "react";
import { Link } from "react-router-dom";
import { GoHistory, GoHome } from "react-icons/go";
import { CgDetailsMore } from "react-icons/cg";
import { CiMoneyCheck1, CiViewList } from "react-icons/ci";
import { RiLockPasswordLine, RiTeamLine } from "react-icons/ri";
import { VscGitPullRequestCreate } from "react-icons/vsc";
import { FiUserCheck } from "react-icons/fi";
import { MdOutlineAccountBalance } from "react-icons/md";
import { BiUserPlus } from "react-icons/bi";
import useData from "../../Context/useData";
import { IoCreateOutline } from "react-icons/io5";
import { BsCalendar4Event } from "react-icons/bs";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { FaRegMoneyBillAlt } from "react-icons/fa";

const Sidebar = ({ show, setShow }) => {
  const { allRoles } = useData().user;
  const userAdmin = allRoles.includes("User admin");
  const subAdmin = allRoles.includes("Sub admin");
  const superAdmin = allRoles.includes("Super admin");

  return (
    <aside>
      {show && (
        <div
          onClick={() => setShow(false)}
          className="z-30  overflow-y-scroll left-0 h-full w-full bg-black opacity-10 lg:hidden "
        ></div>
      )}
      <div
        className={`w-72 h-full fixed top-0 overflow-y-scroll transition-all duration-200 shadow-lg  ${
          show ? "left-0" : "-left-full"
        } p-2 bg-white z-40`}
      >
        <div className="p-4 ">
          <ul className="">
            <li
              onClick={() => {
                if (window.innerWidth < 500) {
                  setShow(false);
                }
              }}
              className="py-2 flex items-center"
            >
              <GoHome size={20} />
              <button className="ml-2">
                <Link to="/" className="ml-2 text-base font-medium">
                  Home
                </Link>
              </button>
            </li>

            <li className="py-2 flex items-center">
              <CgDetailsMore size={20} />
              <button className="ml-2">
                <Link
                  to="account-details"
                  className="ml-2 text-base font-medium"
                >
                  Account Details
                </Link>
              </button>
            </li>

            <li className="py-2 flex items-center">
              <CiMoneyCheck1 size={20} />
              <button className="ml-2">
                <Link
                  to="commission-withdraw"
                  className="ml-2 text-base font-medium"
                >
                  Commission Withdraw
                </Link>
              </button>
            </li>

            <li className="py-2 flex items-center">
              <GoHistory size={20} />
              <button className="ml-2">
                <Link
                  to="balance-history"
                  className="ml-2 text-base font-medium"
                >
                  Balance history
                </Link>
              </button>
            </li>

            {userAdmin && (
              <li className="py-2 flex items-center">
                <VscGitPullRequestCreate size={20} />
                <button className="ml-2">
                  <Link
                    to="create-account"
                    className="ml-2 text-base font-medium"
                  >
                    Create Account
                  </Link>
                </button>
              </li>
            )}

            {superAdmin && (
              <li className="py-2 flex items-center">
                <BiUserPlus size={20} />
                <button className="ml-2">
                  <Link
                    to="create-admin"
                    className="ml-2 text-base font-medium"
                  >
                    Create Admin
                  </Link>
                </button>
              </li>
            )}

            {subAdmin && (
              <li className="py-2 flex items-center">
                <FiUserCheck size={20} />
                <button className="ml-2">
                  <Link
                    to="active-request"
                    className="ml-2 text-base font-medium"
                  >
                    Account Active
                  </Link>
                </button>
              </li>
            )}
            {superAdmin && (
              <li className="py-2 flex items-center">
                <MdOutlineAccountBalance size={20} />
                <button className="ml-2">
                  <Link
                    to="balance-send"
                    className="ml-2 text-base font-medium"
                  >
                    Balance Send
                  </Link>
                </button>
              </li>
            )}

            <li className="py-2 flex items-center">
              <RiLockPasswordLine size={20} />
              <button className="ml-2">
                <Link to="set-pin" className="ml-2 text-base font-medium">
                  Set Pin
                </Link>
              </button>
            </li>

            {superAdmin && (
              <li className="py-2 flex items-center">
                <IoCreateOutline size={20} />
                <button className="ml-2">
                  <Link
                    to="create-course"
                    className="ml-2 text-base font-medium"
                  >
                    Create Course
                  </Link>
                </button>
              </li>
            )}

            {subAdmin && (
              <li className="py-2 flex items-center">
                <RiTeamLine size={20} />
                <button className="ml-2">
                  <Link
                    to="my-affiliate"
                    className="ml-2 text-base font-medium"
                  >
                    My Affiliate
                  </Link>
                </button>
              </li>
            )}

            {superAdmin && (
              <li className="py-2 flex items-center">
                <BsCalendar4Event size={20} />
                <button className="ml-2">
                  <Link
                    to="create-event"
                    className="ml-2 text-base font-medium"
                  >
                    Create Event
                  </Link>
                </button>
              </li>
            )}

            {superAdmin && (
              <li className="py-2 flex items-center">
                <AiOutlineUserSwitch size={20} />
                <button className="ml-2">
                  <Link
                    to="set-designation"
                    className="ml-2 text-base font-medium"
                  >
                    Create Designation
                  </Link>
                </button>
              </li>
            )}

            {superAdmin && (
              <li className="py-2 flex items-center">
                <FaRegMoneyBillAlt size={20} />
                <button className="ml-2">
                  <Link
                    to="commision-user"
                    className="ml-2 text-base font-medium"
                  >
                    All Commissions
                  </Link>
                </button>
              </li>
            )}

            {superAdmin && (
              <li className="py-2 flex items-center">
                <CiViewList size={20} />
                <button className="ml-2">
                  <Link
                    to="admin/withdraw-list"
                    className="ml-2 text-base font-medium"
                  >
                    All Withdraw List
                  </Link>
                </button>
              </li>
            )}
            {!superAdmin && (
              <li className="py-2 flex items-center">
                <CiViewList size={20} />
                <button className="ml-2">
                  <Link
                    to="withdraw-list"
                    className="ml-2 text-base font-medium"
                  >
                    User Withdraw List
                  </Link>
                </button>
              </li>
            )}

            {superAdmin && (
              <>
                <li className="py-2 flex items-center">
                  <CiViewList size={20} />
                  <button className="ml-2">
                    <Link
                      to="student-batch"
                      className="ml-2 text-base font-medium"
                    >
                      Assign student
                    </Link>
                  </button>
                </li>

                <li className="py-2 flex items-center">
                  <CiViewList size={20} />
                  <button className="ml-2">
                    <Link
                      to="create-batch"
                      className="ml-2 text-base font-medium"
                    >
                      Create Batch
                    </Link>
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;

// if (window.innerWidth < 500) {
//   setShow(false);
// }
