import React, { useEffect, useState } from "react";
import useData from "../../Context/useData";
import { baseUrl } from "../../helper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdDoneAll } from "react-icons/io";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";

const ActiveAccount = () => {
  const { token, myProfile } = useData().user;
  const [activeAccounts, setActiveAccounts] = useState(null);
  const [update, setUpdate] = useState(false);
  const [loader, setLoader] = useState(false);

// console.log("myProfile", myProfile);

  // table data
  useEffect(() => {
    if (!token ) {
      return;
    }

    if(!myProfile){
      return
    }

    
    const activeReq = async () => {
      setLoader(true);
   try {
    const res = await fetch(
      `${baseUrl.url}/student/ref/data`,
      {
        method: "Get",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const resData = await res.json();
    if (res.ok) {
      setActiveAccounts(resData?.ref);
      toast.success(resData?.message)

    } else {
 toast.error(resData?.message)
    }
   } catch (error) {
    toast.error(error.message)
   }
   finally{
    setLoader(false)
   }
    };
    activeReq();
  }, [token, myProfile, update]);

  const handleApprove = async (id) => {
    if (!id) {
      return;
    }

    try {
      const res = await fetch(`${baseUrl.url}/student/ref/approve/${id}`, {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.success(resData.message);
      } else {
        const resData = await res.json();
        toast.error(resData.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDisApprove = async (id) => {
    if (!id) {
      return;
    }
    const res = await fetch(`${baseUrl.url}/student/ref/disapprove/${id}`, {
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const resData = await res.json();
    if (res.ok) {
      setUpdate(!update);
      toast.success(resData.message);
    } else {
      const resData = await res.json();
      toast.error(resData.message);
    }
  };

  const StudentTemplate = (data) => {
    return <p>{data?.student_login_id}</p>;
  };
  const ReferTemplate = (data) => {
    return <p>{data?.ref_login_id}</p>;
  };

  const PlacementTemplate = (data) => {
    return <p>{data?.placement_login_id}</p>;
  };

  const NetOfficeTemplate = (data) => {
    return <p>{data?.net_office}</p>;
  };

  const PositionTemplate = (data) => {
    return <p>{data?.position === "1" ? "A" : "B"}</p>;
  };

  const DateTemplate = (data) => {
    const formetDate = moment(data?.updated_at).format("MM-DD-YY");
    return <p>{formetDate}</p>;
  };
  const StatusTemplate = (data) => {
    return (
      <div>
        {data.status === "0" ? (
          <p className="text-green-500">Pending</p>
        ) : (
          <p className=" text-green-500">Approve</p>
        )}
      </div>
    );
  };

  const ActionTemplate = (data) => {
    return (
      <Menu
        direction={"right"}
        align={"center"}
        menuButton={
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>
        }
        transition
      >
        <MenuItem
          className="hover:text-green-500"
          onClick={() => handleApprove(data?.id)}
        >
          <span>
            <IoMdDoneAll className="mr-2" />
          </span>{" "}
          Approve
        </MenuItem>

        <MenuItem
          className="hover:text-green-500"
          onClick={() => handleDisApprove(data?.id)}
        >
          <span>
            <IoMdDoneAll className="mr-2" />
          </span>{" "}
          DisApprove
        </MenuItem>
      </Menu>
    );
  };

  return (
    <div className="card pt-10 px-4">
      <p className="pb-5 text-2xl">Account Active </p>
      {loader ? (
        <Loader />
      ) : (
        <DataTable
          value={activeAccounts}
          paginator
          rows={10}
          dataKey="id"
          size="small"
          emptyMessage="No customers found."
        >
          <Column field="name" header="Student ID" body={StudentTemplate} />
          <Column field="name" header="Refer ID" body={ReferTemplate} />
          <Column field="name" header="Placement ID" body={PlacementTemplate} />
          <Column
            field="name"
            header="Net Office ID"
            body={NetOfficeTemplate}
          />
          <Column
            field="name"
            header="Position"
            body={PositionTemplate}
            sortable
          />
          <Column field="name" header="Status" body={StatusTemplate} />
          <Column field="name" header="Date" body={DateTemplate} />
          <Column field="name" header="Action" body={ActionTemplate} />
        </DataTable>
      )}
    </div>
  );
};

export default ActiveAccount;
