import React from "react";
import bannar from "../../assets/bannar.jpg";
import { Link } from "react-router-dom";
import { MdModeEditOutline } from "react-icons/md";
import useData from "../../Context/useData";

const MyAccount = () => {
  const {myProfile, allRoles, userDesignation} = useData().user
  return (
    <div className="p-3 py-5 shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-5 mb-4">
      <h1>Welcome {myProfile?.name}</h1>
      <div className="mt-5 shadow pb-2">
        <img className="w-full max-w-full" src={bannar} alt="" />
      </div>
      <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5 my-10">
        <div className="  shadow-sm">
          <h1 className="text-[23px] shadow p-2 pl-5 font-semibold">
            Account Information
          </h1>
          <div className="p-5">
            <p className="mt-1">Login ID: {myProfile?.login_id}</p>
            <p className="mt-1">Name: {myProfile?.name}</p>
            <p className="mt-1">Mobile: {myProfile?.mobile}</p>
          </div>
        </div>

        <div className="  shadow-sm">
          <h1 className="text-[23px] shadow p-2 pl-5 font-semibold flex items-center gap-5">
            <span>Profile Information</span>
            <Link>
              <MdModeEditOutline className="text-[23px] " />
            </Link>
          </h1>
          <div className="p-5">
            <p className="mt-1">E-mail: {myProfile?.email} </p>
            <p className="mt-1">User Type: {allRoles[0]} </p>
          </div>
        </div>

        <div className="  shadow-sm">
          <h1 className="text-[23px] shadow p-2 pl-5 font-semibold flex items-center gap-5">
            <span>Business Status</span>
            <Link>
              {" "}
              <MdModeEditOutline className="text-[23px] " />
            </Link>
          </h1>
          <div className="p-5">
            <p className="mt-1"> Designation: {userDesignation?.name}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
