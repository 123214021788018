import React, { useEffect, useState } from "react";
import CreateBatchModal from "../CreateBatchModal";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import useData from "../../../Context/useData";
import { baseUrl } from "../../../helper";
import { toast } from "react-toastify";
import { AiOutlineDelete } from "react-icons/ai";
import moment from "moment";

const CreateBatch = () => {
  const { token } = useData().user;
  const [visible, setVisible] = useState(false);
  const [allBatch, setAllBatch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [limitData, setLimitData] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [expandedRows, setExpandedRows] = useState(null);
  const modalVisible = { visible, setVisible };

  useEffect(() => {
    if (!token) {
      return;
    }
    const getCourse = async () => {
      setLoading(true);
      try {
        const res = await fetch(`${baseUrl.url}/batch`, {
          method: "Get",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setAllBatch(resData?.data?.data);
          setTotalData(resData?.data?.total);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    getCourse();
  }, [token, update]);

  const allowExpansion = (rowData) => {
    return rowData;
  };

  const handleDelete = async (id, name) => {
    const agree = window.confirm(`are you sure to delete ${id} and ${name}`);
    if (agree) {
      try {
        const res = await fetch(`${baseUrl.url}/batch/${id}`, {
          method: "delete",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res.ok) {
          toast.success("successfully deleted");
          setUpdate(!update);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const batchNameTem = (data) => {
    console.log("batch-data-->", data);
    return <p>{data?.name}</p>;
  };

  const courseNameTem = (data) => {
    return <p>{data.course?.title}</p>;
  };

  const classTimeTem = (data) => {
    return <p>{data.time}</p>;
  };

  const createDateTem = (data) => {
    const time = moment(data.updated_at).format("MMMM Do YYYY, h:mm:ss a");
    return <p>{time}</p>;
  };

  const ActionTemplate = (data) => {
    console.log("data", data);
    const id = data?.id;
    const name = data?.name;
    return (
      <div className=" flex items-center gap-x-2">
        {/* <div
          className="bg-green-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => {
            setVisible(true);
            setPreViousData(data);
          }}
        >
          <AiFillEdit className="text-white" />
        </div> */}
        <div
          className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => handleDelete(id, name)}
        >
          <AiOutlineDelete className="text-white" />
        </div>
      </div>
    );
  };

  const userIdTemplate = (data) => {
    console.log("expande", data);
    return <p>{data.login_id}</p>;
  };

  const userNameTemplate = (data) => {
    return <p>{data.name}</p>;
  };

  const userEmailTemplate = (data) => {
    return <p>{data.email}</p>;
  };

  const userMobileTemplate = (data) => {
    return <p>{data.mobile}</p>;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <DataTable value={data.students}>
          <Column header="User Id" body={userIdTemplate}></Column>
          <Column header="Name" body={userNameTemplate}></Column>
          <Column header="Email" body={userEmailTemplate}></Column>
          <Column header="Mobile" body={userMobileTemplate}></Column>
        </DataTable>
      </div>
    );
  };

  return (
    <div className="ps-4 py-10">
      <div className="card">
        <div className="pb-3 flex justify-between items-center">
          <h4 className="text-2xl font-medium">Create Student Batch</h4>
          <div className="flex justify-between items-center gap-x-4">
            <Button
              icon="pi pi-plus"
              severity="success"
              size="small"
              label="Create Batch"
              onClick={() => setVisible(true)}
            />
          </div>
        </div>

        <DataTable
          value={allBatch}
          emptyMessage="No Batch found."
          size="small"
          first={pageNumber}
          paginator
          rows={limitData}
          totalRecords={totalData}
          loading={loading}
          rowHover={true}
          lazy
          onPage={(e) => {
            setPageNumber(e.first);
            setLimitData(e.rows);
          }}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander={allowExpansion} style={{ width: "5rem" }} />
          <Column header="Batch Name" body={batchNameTem}></Column>
          <Column header="Course Name" body={courseNameTem}></Column>
          <Column header="Class time" body={classTimeTem}></Column>
          <Column header="Create Date" body={createDateTem}></Column>
          <Column header="Action" body={ActionTemplate}></Column>
        </DataTable>

        {/* <DataTable
          value={allBatch}
          first={pageNumber}
          paginator
          rows={limitData}
          totalRecords={totalData}
          // loading={loading}
          // selection={selectedStudent}
          // onSelectionChange={(e) => setSelectedStudent(e.value)}
          dataKey="id"
          onPage={(e) => {
            setPageNumber(e.first);
            setLimitData(e.rows);
          }}
          tableStyle={{ minWidth: "50rem" }}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          // onRowExpand={onRowExpand}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander={allowExpansion} style={{ width: "5rem" }} />
          <Column
            field="phone"
            header="Batch Name"
            body={batchNameTem}
          ></Column>
          <Column header="Class time" body={classTimeTem}></Column>
          <Column body={createDateTem} header="Create Date"></Column>
          <Column body={ActionTemplate} header="Action"></Column>
        </DataTable> */}
      </div>
      {<CreateBatchModal modalVisible={modalVisible} />}
    </div>
  );
};

export default CreateBatch;
