import React, { useEffect, useState } from "react";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";
import { baseUrl } from "../../helper";
import { toast } from "react-toastify";
import useData from "../../Context/useData";

const Test = () => {
  const { token, allCourse } = useData().user;
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [userList, setUserList] = useState([]);
  const [totalData, setTotalData] = useState(0);

  useEffect(() => {
    if (!token) {
      return;
    }
    const getProfile = async () => {
      try {
        setLoading(true);
        const res = await fetch(`${baseUrl.url}/all/user?page=${+pageNumber}`, {
          method: "Get",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setTotalData(resData.data?.total);
          const userId = resData.data.data?.map((user) => {
            let obj = {};
            obj.label = user?.login_id;
            obj.value = user?.id;
            return obj;
          });
          setUserList([...userList, ...userId]);
        }
      } catch (e) {
        console.error(e.message);
      } finally {
        setLoading(false);
      }
    };
    getProfile().then();
  }, [token, pageNumber]);

  console.log("userList", userList);
  const [select, setSelect] = useState();

  const changeEvent = (e) => {
    return console.log("change", e);
  };

  const MenuList = () => {
    // console.log("menuListProps", menuListProps)
    // console.log("change", menuListProps.selectProps.inputValue)
    return (
      <div
        id="scrollableDiv"
        className=""
        style={{ height: "200px", overflowY: "scroll" }}
      >
        <InfiniteScroll
          dataLength={userList.length}
          next={() => {
            console.log("next--->");
            setPageNumber((prevPage) => prevPage + 1);
            // setUserList((prev) => [...prev, ...newArray]);
          }}
          hasMore={totalData > userList?.length}
          loader={<h4>Loading...</h4>}
          className="message"
          scrollableTarget="scrollableDiv"
        >
          {userList?.map((opp, i) => {
            return (
              <p
                key={i}
                // onClick={(e) => console.log("select User", e.target.value)}
                className="py-1 hover:bg-gray-100"
              >
                {opp.label}
              </p>
            );
          })}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div>
      <Select
        defaultMenuIsOpen={false}
        isLoading={loading}
        options={userList}
        onChange={changeEvent}
        onInputChange={(e) => console.log("changeE", e)}
        components={{
          MenuList,
        }}
      />
    </div>
  );
};

export default Test;
