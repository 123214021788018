import Dashboard from "../Layout/Dashboard/Dashboard";
import { createBrowserRouter } from "react-router-dom";
import Login from "../Pages/Login/Login";
import Home from "../Pages/Home/Home";
import AccountDetails from "../Components/AccountDetails/AccountDetails";
import CommissionWithdraw from "../Components/ComissionWithdraw/CommissionWithdraw";
import BalanceTransfer from "../Components/BalanceTransfer/BalanceTransfer";
import CreateAccount from "../Components/CreateAccount/CreateAccount";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import CreateAdmin from "../Components/CreateAdmin/CreateAdmin";
import ActiveAccount from "../Components/ActiveAccount/ActiveAccount";
import BalanceSend from "../Components/BalanceSend/BalanceSend";
import SetPin from "../Components/SetPin/SetPin";
import CreateCourse from "../Components/CreateCourse/CreateCourse";
import MyAffiliate from "../Components/MyAffiliate/MyAffiliate";
import CreateEvent from "../Components/CreateEvent/CreateEvent";
import Designation from "../Components/Designation/Designation";
import CommisionTable from "../Components/CommisionTable/CommisionTable";
import BalanceHistory from "../Components/BalanceHistory/BalanceHistory";
import WithdrawReq from "../Components/WithdrawReq/WithdrawReq";
import AdminWithdrawList from "../Components/AdminWithdrawList/AdminWithdrawList";
import WithdrawList from "../Components/WithdrawList/WithdrawList";
import StudentBatchPage from "../Components/StudentBatchPage/StudentBatchPage";
import CreateBatch from "../Components/StudentBatchPage/Batch/CreateBatch";
import Test from "../Components/Test/Test";
export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/account-details",
        element: <AccountDetails />,
      },
      {
        path: "/commission-withdraw",
        element: <CommissionWithdraw />,
      },
      {
        path: "balance-transfer",
        element: <BalanceTransfer />,
      },
      {
        path: "balance-history",
        element: <BalanceHistory />,
      },
      {
        path: "create-account",
        element: <CreateAccount />,
      },
      {
        path: "create-admin",
        element: <CreateAdmin />,
      },
      {
        path: "active-request",
        element: <ActiveAccount />,
      },
      {
        path: "balance-send",
        element: <BalanceSend />,
      },
      {
        path: "set-pin",
        element: <SetPin />,
      },
      {
        path: "create-course",
        element: <CreateCourse />,
      },
      {
        path: "my-affiliate",
        element: <MyAffiliate />,
      },
      {
        path: "create-event",
        element: <CreateEvent />,
      },
      {
        path: "set-designation",
        element: <Designation />,
      },
      {
        path: "commision-user",
        element: <CommisionTable />,
      },
      {
        path: "withdraw-req",
        element: <WithdrawReq />,
      },
      {
        path: "admin/withdraw-list",
        element: <AdminWithdrawList />,
      },
      {
        path: "withdraw-list",
        element: <WithdrawList />,
      },
      {
        path: "student-batch",
        element: <StudentBatchPage />,
      },
      {
        path: "create-batch",
        element: <CreateBatch />,
      },
      {
        path: "test",
        element: <Test />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
