import React, { useEffect, useState } from "react";
import useData from "../../Context/useData";
import { baseUrl } from "../../helper";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import moment from "moment";

const BalanceHistory = () => {
  const { token } = useData().user;
  const [balance, setBalance] = useState([]);
  const [limitData, setLimitData] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  // let pages = pageNumber / limitData + 1;

  useEffect(() => {
    if (!token) {
      return;
    }
    const getUser = async () => {
      setLoading(true);
      try {
        const res = await fetch(`${baseUrl.url}/user/ref-bonus/list`, {
          method: "Get",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setBalance(resData.data.data);
          setTotalData(resData.data.total);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    getUser();
  }, [token]);

  if (loading) {
    return <Loader />;
  }

  const bonusTypeTem = (data) => {
    const bonusType = data.type;
    return <p>{bonusType === "Matching bonus" ? "Basic bonus" : bonusType}</p>;
  };

  const CategoryTemplate = (data) => {
    const type = data?.amount;
    return <p>{type > 0 ? "Debit" : "Cadit"}</p>;
  };

  const dateTemplate = (data) => {
    const date = moment(data?.updated_at).format("DD-MM-YY");
    return <p>{date}</p>;
  };

  return (
    <div>
      <DataTable
        value={balance}
        emptyMessage="No Account found"
        size="small"
        first={pageNumber}
        paginator
        rows={limitData}
        totalRecords={totalData}
        loading={loading}
        rowHover={true}
        lazy
        onPage={(e) => {
          setPageNumber(e.first);
          setLimitData(e.rows);
        }}
        tableStyle={{ minWidth: "50rem" }}
      >
        <Column
          header="#"
          headerStyle={{ width: "3rem" }}
          body={(data, options) => options.rowIndex}
        ></Column>
        <Column body={bonusTypeTem} header="Name"></Column>
        <Column field="amount" header="Amount"></Column>
        <Column field="" body={CategoryTemplate} header="Category"></Column>
        <Column field="Date" body={dateTemplate} header="Date"></Column>
      </DataTable>
    </div>
  );
};

export default BalanceHistory;
