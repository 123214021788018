import React, { useEffect, useState } from "react";
import useData from "../../Context/useData";
import { baseUrl } from "../../helper";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";

const WithdrawList = () => {
  const { token } = useData().user;
  const [loader, setLoader] = useState(false);
  const [withdrawList, setWithdrawList] = useState(null);
  const [limitData, setLimitData] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalData, setTotalData] = useState(0);
  let pages = pageNumber / limitData + 1;

  // student_refer
  useEffect(() => {
    if (!token) {
      return;
    }
    const getWithdrawList = async () => {
      setLoader(true);
      try {
        const res = await fetch(
          `${baseUrl.url}/withdraw/request-list?page=${pages.toFixed(0)}`,
          {
            method: "Get",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const resData = await res.json();
        if (res.ok) {
          setWithdrawList(resData.data?.data);
          setTotalData(resData.data?.total);
          // change state name
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoader(false);
      }
    };
    getWithdrawList();
  }, [token, pages]);

  const DateTemplate = (data) => {
    const formetDate = moment(data.updated_at).format("DD-MM-YY");
    return <p>{formetDate}</p>;
  };

  if (loader) {
    return <Loader />;
  }

  const PaymenytType = (data) => {
    return (
      <p>{data.payment_type === "Bikash" ? "Bkash" : data.payment_type}</p>
    );
  };

  return (
    <section className="py-10 px-4">
      <div className="card">
        <DataTable
          value={withdrawList}
          first={pageNumber}
          paginator
          rows={limitData}
          totalRecords={totalData}
          loading={loader}
          rowHover={true}
          lazy
          onPage={(e) => {
            setPageNumber(e.first);
            setLimitData(e.rows);
          }}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column field="phone" header="Phone"></Column>
          <Column body={PaymenytType} header="Payment"></Column>
          <Column field="amount" header="Amount"></Column>
          <Column field="code" header="Code"></Column>
          <Column body={DateTemplate} header="Date"></Column>
        </DataTable>
      </div>
    </section>
  );
};

export default WithdrawList;
