import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Chip } from "primereact/chip";
import React, { useState } from "react";
import { baseUrl } from "../../helper";
import useData from "../../Context/useData";
import { toast } from "react-toastify";

const AssignStudent = ({
  modalAssign,
  selectedStudent,
  setSelectedStudent,
}) => {
  const { token } = useData().user;
  const { showAssign, setShowAssign, allBatch } = modalAssign;
  const [selectBatch, setSelectBatch] = useState(null);

  const batchList = allBatch?.map((batch) => {
    let obj = {};
    obj.label = batch.name;
    obj.value = batch?.id;
    return obj;
  });

  const ids = selectedStudent?.map((student) => {
    return student.id;
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("user_id", ids);
    try {
      const res = await fetch(
        `${baseUrl.url}/batch/assign/student/${selectBatch}`,
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (res.ok) {
        toast.success("Successfully Student Assign");
        setSelectBatch(null);
        setSelectedStudent(null);
        setShowAssign(false);
      } else {
        const resData = await res.json();
        console.log("message", resData);
        toast.error(resData.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div>
      <Dialog
        header="Header"
        visible={showAssign}
        onHide={() => setShowAssign(false)}
        style={{ width: "35vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-y-1">
            <label className="">Student List</label>

            <div className="p-2 border rounded flex gap-x-2">
              {selectedStudent?.map((student, i) => {
                return <Chip key={i} label={student.login_id} />;
              })}
            </div>
          </div>

          <div className="mt-4 flex flex-col gap-y-2">
            <label>Select Batch</label>
            <Dropdown
              value={selectBatch}
              onChange={(e) => setSelectBatch(e.value)}
              options={batchList}
              editable
              optionLabel="label"
              placeholder="Select a Batch"
              className="w-full md:w-14rem"
            />
          </div>

          <div className="mt-4">
            <Button
              icon="pi pi-plus"
              severity="success"
              size="small"
              label="Primary"
            />
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default AssignStudent;
