import React, { useState } from "react";
import logo from "../../assets/icon.jpg";
import { baseUrl } from "../../helper";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import useData from "../../Context/useData";

const Login = () => {
  const {setToken} = useData().user;
  const navigate = useNavigate()
  const [view, setView] = useState(false);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const userName = form.name.value;
    const Password = form.Password.value;
    const postData = {
      login_id: userName,
      password: Password,
    };

    let formData = new FormData();
    for (const i in postData) {
      const element = postData[i];
      formData.append(`${i}`, element);
    }

    const handleLogIn = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/admin/login`, {
          method: "Post", 
          credentials: "include",
          body: formData,
        });
        const resData = await res.json();
        if (res.ok) {
          setToken(resData?.token)
          toast.success("Successfully login");
          navigate("/")
        }
        else{
          toast.error(resData.errors[0])
        }
      } catch (error) {
          console.error(error.message)
      }
    };

    handleLogIn();
  };
  return (
    <div>
      <div className="grid grid-cols-1">
        <div className="h-screen bgRight">
          <div className="flex justify-center items-center h-full w-full">
            <form
              onSubmit={handleSubmit}
              className="shadow-lg xl:w-1/3 lg:w-1/3 md:w-8/12 w-11/12 lg:p-8 p-4 bg-white rounded"
            >
              <div className="flex justify-center w-full">
                <div className="h-20 w-40 mb-8">
                  <img src={logo} alt="" className="h-full w-full " />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="pb-2">User Id</label>
                <input
                  type="number"
                  className="border border-gray-700 rounded py-2 ps-3"
                  name="name"
                  placeholder="User Id"
                />
              </div>
              <div className="flex flex-col pt-4 ">
                <label className="pb-2 text-white">Password</label>
                <div className="relative">
                  <input
                    type={!view ? "password" : "text"}
                    // defaultValue="12345678"
                    className="rounded py-2 ps-3 border border-gray-700 focus-visible:outline-none w-full"
                    name="Password"
                    placeholder="******"
                  />
                  <div
                    className="absolute top-3 right-4 cursor-pointer"
                    onClick={() => setView(!view)}
                  >
                    {!view ? <BsEyeSlash /> : <BsEye />}
                  </div>
                </div>
              </div>
              <div className="">
                <input
                  type="submit"
                  value="LogIn"
                  className="py-2 px-4 bgRight rounded mt-6 cursor-pointer border"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
