import React, { useState } from "react";
import { baseUrl, countries, district, showDummyImage } from "../../helper";
import useData from "../../Context/useData";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

const AccountDetails = () => {
  const { token, myProfile } = useData().user;
  const [image, setImage] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectDistrict, setSelectDistrict] = useState(null);
  const [changeValue, setChangeValue] = useState({});
  const [birthDate, setBirthDate] = useState(null);
  const [selectImg, setSelectImg] = useState(null);
  const setValue = (e) => {
    setChangeValue({
      ...changeValue,
      [e.target.name]: e.target.value,
    });
  };
  const imgChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0]);
      fileReader.onload = () => {
        setImage(fileReader.result);
        setSelectImg(e.target.files[0]);
      };
    }
  };

  // change password
  const handlePassChange = async (e) => {
    e.preventDefault();

    const form = e.target;
    const current_password = form.current_password.value;
    const new_password = form.new_password.value;
    const confirm_password = form.confirm_password.value;

    const data = {
      current_password: current_password,
      new_password: new_password,
      confirm_password: confirm_password,
    };

    let formData = new FormData();
    for (const i in data) {
      const element = data[i];
      formData.append(`${i}`, element);
    }

    const postData = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/change/passowrd`, {
          method: "Put",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        if (res.ok) {
          toast.success("successfully updated");
          form.reset();
        } else {
          const resData = await res.json();
          toast.error(resData.message);
          form.reset();
        }
      } catch (error) {
        console.error(error?.message);
      }
    };
    postData();
  };

  const handleChangeInfo = (e) => {
    if (!myProfile?.id) {
      return;
    }
    e.preventDefault();
    const form = e.target;

    const data = {
      name: changeValue.name || myProfile.name,
      mobile: changeValue.mobile || myProfile.mobile,
      address: changeValue.address || myProfile.address,
      nid_no: changeValue.nid_no || myProfile.nid_no,
      father_name: changeValue.father_name || myProfile.father_name,
      father_number: changeValue.father_number || myProfile.father_number,
      guardian_name: changeValue.guardian_name || myProfile.guardian_name,
      guardian_number: changeValue.guardian_number || myProfile.guardian_number,
      contact_person: changeValue.contact_person || myProfile.contact_person,
      date_of_birth: birthDate || myProfile.date_of_birth,
      country: selectedCountry || myProfile.country,
      district: selectDistrict || myProfile.district,
      image: selectImg || "",
    };

    let formData = new FormData();
    for (const i in data) {
      const element = data[i];
      formData.append(`${i}`, element);
    }

    const postData = async () => {
      let res = await fetch(`${baseUrl.url}/student/update/${myProfile?.id}`, {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (res.ok) {
        toast.success("successfully updated");
        form.reset();
      } else {
        const resData = await res.json();
        toast.error(resData.message);
        form.reset();
      }
    };
    postData();
  };

  return (
    <div className="pt-10 px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
        {/* account */}
        <form onSubmit={handleChangeInfo} className="common">
          <h1 className="text-lg font-bold text-stone-500 text-center mb-5">
            Account details
          </h1>

          <div className="flex flex-col gap-y-1 ">
            <label className=" ps-1">Name</label>
            <input
              type="text"
              className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
              placeholder="Name"
              name="name"
              defaultValue={myProfile?.name}
              onChange={(e) => setValue(e)}
            />
          </div>

          <div className="flex flex-col gap-y-1 ">
            <label className=" ps-1">Mobile Number</label>
            <input
              type="text"
              className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
              placeholder="Mobile"
              name="mobile"
              defaultValue={myProfile?.mobile}
              onChange={(e) => setValue(e)}
            />
          </div>

          <div className="flex flex-col gap-y-1 ">
            <label className=" ps-1">address</label>
            <input
              type="text"
              className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
              placeholder="Address"
              name="address"
              defaultValue={myProfile?.address}
              onChange={(e) => setValue(e)}
            />
          </div>

          <div className="flex flex-col gap-y-1 pt-2">
            <label className=" ps-1">NID</label>
            <input
              type="Number"
              className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
              placeholder="NID Number"
              name="nid_no"
              defaultValue={myProfile?.nid_no}
              onChange={(e) => setValue(e)}
            />
          </div>

          <div className="flex flex-col gap-y-1 pt-2">
            <label className=" ps-1">Father Name</label>
            <input
              type="text"
              className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
              placeholder="Father Name"
              name="father_name"
              defaultValue={myProfile?.father_name}
              onChange={(e) => setValue(e)}
            />
          </div>

          <div className="flex flex-col gap-y-1 pt-2">
            <label className=" ps-1">Father Number</label>
            <input
              type="Number"
              className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
              placeholder="Father Number"
              name="father_number"
              defaultValue={myProfile?.father_number}
              onChange={(e) => setValue(e)}
            />
          </div>

          <div className="flex flex-col gap-y-1 pt-2">
            <label className=" ps-1">Guardian Name</label>
            <input
              type="text"
              className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
              placeholder="Guardian Name"
              name="guardian_name"
              defaultValue={myProfile?.guardian_name}
              onChange={(e) => setValue(e)}
            />
          </div>

          <div className="flex flex-col gap-y-1 pt-2">
            <label className=" ps-1">Guardian Number</label>
            <input
              type="text"
              className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
              placeholder="Guardian Name"
              name="guardian_number"
              defaultValue={myProfile?.guardian_number}
              onChange={(e) => setValue(e)}
            />
          </div>

          <div className="flex flex-col gap-y-1 pt-2">
            <label className=" ps-1">Contact Person</label>
            <input
              type="text"
              className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
              placeholder="Contact Person"
              name="contact_person"
              defaultValue={myProfile?.contact_person}
              onChange={(e) => setValue(e)}
            />
          </div>

          <div className="flex flex-col gap-y-1 pt-2">
            <label className="ps-1">Date Of Birth</label>
            <Calendar
              value={birthDate}
              onChange={(e) => setBirthDate(e.value)}
              placeholder={myProfile?.date_of_birth}
            />
          </div>

          <div className="flex flex-col gap-y-1 pt-2">
            <label className="ps-1">Select Country</label>
            <Dropdown
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.value)}
              options={countries}
              optionLabel="label"
              placeholder={
                myProfile?.country ? myProfile.country : "Select a Country"
              }
            />
          </div>

          <div className="flex flex-col gap-y-1 pt-2">
            <label className="ps-1">Select District</label>
            <Dropdown
              value={selectDistrict}
              onChange={(e) => setSelectDistrict(e.value)}
              options={district}
              optionLabel="label"
              placeholder={
                myProfile?.district ? myProfile.district : "Select a Country"
              }
              filter
            />
          </div>

          <div className="w-40 h-40 rounded relative mt-3">
            <img
              src={image ? image : `${baseUrl.url}/${image}`}
              alt=""
              onError={({ currentTarget }, imageSize = "160x160") =>
                showDummyImage({ currentTarget }, imageSize)
              }
              className=" h-full w-full rounded"
            />

            <input
              type="file"
              className={`absolute top-0 left-0 w-48 h-full rounded opacity-0`}
              name="profile_photo"
              accept={`"image/*"`}
              onChange={imgChange}
            />
          </div>

          <input
            type="submit"
            value="Submit"
            className="py-2 px-4 bgRight rounded mt-6 cursor-pointer border bg-green-500 text-white"
          />
        </form>

        {/* profile  */}
        <form onSubmit={handlePassChange} className="pt-10 md:pt-0">
          <h1 className="text-lg font-bold text-stone-500 text-center mb-5">
            Change Password
          </h1>
          <div className="flex flex-col gap-y-1">
            <label className=" ps-1">Current Password</label>
            <input
              type="Number"
              className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
              placeholder="Current Password"
              name="current_password"
            />
          </div>

          <div className="flex flex-col gap-y-1 pt-2">
            <label className=" ps-1">New Password</label>
            <input
              type="Number"
              className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
              placeholder="New Password"
              name="new_password"
            />
          </div>

          <div className="flex flex-col gap-y-1 pt-2">
            <label className=" ps-1">New Password</label>
            <input
              type="Number"
              className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
              placeholder="Confirm New Password"
              name="confirm_password"
            />
          </div>
          <input
            type="submit"
            value="Submit"
            className="py-2 px-4 bgRight rounded mt-6 cursor-pointer border bg-green-500 text-white"
          />
        </form>
      </div>
    </div>
  );
};

export default AccountDetails;
