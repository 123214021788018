import React from "react";
import { toast } from "react-toastify";
import useData from "../../Context/useData";
import { baseUrl } from "../../helper";

const SetPin = () => {
  const { token } = useData().user;

  const handlePIn = (e) => {
    e.preventDefault();
    const form = e.target;

    const pin = form.pin.value;
    const ConfirmPin = form.pin.value;

    const data = {
      pin: pin,
      confirm_pin: ConfirmPin,
    };

    let formData = new FormData();
    for (const i in data) {
      const element = data[i];
      formData.append(`${i}`, element);
    }

    const postData = async () => {
      let res = await fetch(`${baseUrl.url}/set/pin?pin&current_pin`, {
        method: "post",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      if (res.ok) {
        toast.success("successfully updated");
        form.reset();
      } else {
        const resData = await res.json();
        toast.error(resData.message);
        form.reset();
      }
    };

    postData();
  };

  const handleResetPin = (e) => {
    e.preventDefault();
    const form = e.target;
    const current_pin = form.current_pin.value;
    const new_pin = form.new_pin.value;

    const data = {
      current_pin: current_pin,
      new_pin: new_pin,
    };

    let formData = new FormData();
    for (const i in data) {
      const element = data[i];
      formData.append(`${i}`, element);
    }

    const postData = async () => {
      let res = await fetch(`${baseUrl.url}/change/pin`, {
        method: "post",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      if (res.ok) {
        toast.success("successfully updated");
        form.reset();
      } else {
        const resData = await res.json();
        toast.error(resData.message);
        form.reset();
      }
    };

    postData();
  };

  return (
    <div className="grid grid-cols-2 gap-x-4 pt-10 px-4">
      <form onSubmit={handlePIn} className="">
        <h1 className="text-lg font-bold text-stone-500 text-center mb-5">
          Create Pin
        </h1>
        <div className="flex flex-col">
          <label className=" ps-1">Pin</label>
          <input
            type="Number"
            className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
            placeholder="Pin"
            name="pin"
          />
        </div>

        <div className="flex flex-col pt-2">
          <label className=" ps-1">Confirm Pin</label>
          <input
            type="Number"
            className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
            placeholder="Confirm Pin"
            name="ConfirmPin"
          />
        </div>

        <button className="mt-5 py-2 px-6 bg-green-500 text-white rounded cursor-pointer">
          Submit
        </button>
      </form>

      <form onSubmit={handleResetPin}>
        <h1 className="text-lg font-bold text-stone-500 text-center mb-5">
          Change Pin
        </h1>

        <div className="flex flex-col ">
          <label className=" ps-1">Create Pin</label>
          <input
            type="Number"
            className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
            placeholder="Create Pin"
            name="current_pin"
          />
        </div>

        <div className="flex flex-col pt-2">
          <label className=" ps-1">New Pin</label>
          <input
            type="Number"
            className="border-2 ps-2 py-1 rounded border-gray-200 focus-visible:outline-none w-full"
            placeholder="New Pin"
            name="new_pin"
          />
        </div>
        <button className="mt-5 py-2 px-6 bg-green-500 text-white rounded cursor-pointer">
          Submit
        </button>
      </form>
    </div>
  );
};

export default SetPin;
