import React, { useEffect, useState } from "react";
import useData from "../../Context/useData";
import { baseUrl } from "../../helper";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { MdKeyboardArrowDown } from "react-icons/md";

import { Dropdown } from "primereact/dropdown";
import Loader from "../Loader/Loader";

const CreateAdmin = () => {
  const { token, roles } = useData().user;
  const [id, setID] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [userRoleId, setUserRoleId] = useState();
  const [show, setShow] = useState(false);
  const [userList, setUserList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [searchUser, setSearchUser] = useState("");
  const [loader, setLoader] = useState(false);

  // get all user
  useEffect(() => {
    if (!token) {
      return;
    }
    const getProfile = async () => {
      try {
        setLoader(true);
        const res = await fetch(
          `${baseUrl.url}/all/user?page=${pageNumber}${
            searchUser && `&login_id=${searchUser}`
          }`,
          {
            method: "Get",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const resData = await res.json();
        if (res.ok) {
          setUserList([...userList, ...resData?.data?.data]);
          setTotalData(resData.data?.total);
          if (searchUser) {
            setUserList(resData?.data?.data);
          }
        }
      } catch (e) {
        console.error(e.message);
      } finally {
        setLoader(false);
      }
    };
    getProfile().then();
  }, [token, pageNumber, searchUser]);

  const userId = userList?.map((user) => {
    let obj = {};
    obj.label = user?.login_id;
    obj.value = user?.id;
    return obj;
  });

  const userRoles = roles?.map((user) => {
    let obj = {};
    obj.label = user?.name;
    obj.value = user?.id;
    return obj;
  });

  // 1st submit

  const handleUser = (e) => {
    e.preventDefault();
    if (!id && !token) {
      return;
    }
    const userDetails = async () => {
      const res = await fetch(`${baseUrl.url}/user/${id.value}`, {
        method: "get",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        setUserDetails(resData.data);
        toast.success("Successfully ");
      }
    };
    userDetails();
  };

  // final subumit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const postData = {
      login_id: userDetails.login_id,
      type: userRoleId,
    };
    let formData = new FormData();
    for (const i in postData) {
      const element = postData[i];
      // formData.append(`${i}`, element);
      if (i === "type") {
        formData.append(`${i}[]`, element);
      } else {
        formData.append(`${i}`, element);
      }
    }

    const postAdmin = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/make/admin`, {
          method: "post",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

        const resData = await res.json();
        if (res.ok) {
          toast.success(resData.message);
          form.reset();
          setUserDetails(null);
        } else {
          form.reset();
          toast.error(resData.message);
        }
      } catch (error) {
        console.error(error.message);
      }
    };
    postAdmin();
  };

  if (!searchUser && loader) {
    return <Loader />;
  }

  return (
    <div className="px-4 pt-10">
      <h2 className="text-xl"> Create User Role & Permission to Login</h2>

      <div className="pt-8">
        <form onSubmit={handleUser}>
          <label className="pb-1 ps-2">Select Student Id</label>
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-x-5">
            <div>
              <div className="flex justify-between items-center border p-2">
                <input
                  type="text"
                  className="w-full border-none myInput"
                  placeholder="Select Student"
                  defaultValue={id.label}
                  onClick={() => setShow(!show)}
                  onChange={(e) => setSearchUser(e.target.value)}
                />
                <MdKeyboardArrowDown size={20} />
              </div>
              {show && (
                <div
                  id="scrollableDiv"
                  className="mt-3"
                  style={{ height: "200px", overflowY: "scroll" }}
                >
                  <InfiniteScroll
                    dataLength={userList?.length}
                    next={() => {
                      setPageNumber((prevPage) => prevPage + 1);
                    }}
                    inverse={false}
                    hasMore={totalData > userList?.length}
                    loader={<h4>Loading...</h4>}
                    className="message"
                    scrollableTarget="scrollableDiv"
                  >
                    <div className="border">
                      {userId?.map((user_uid, i) => {
                        return (
                          <p
                            key={i}
                            onClick={() => {
                              setID(user_uid);
                              setShow(false);
                            }}
                            className="px-2 py-1"
                          >
                            {user_uid.label}
                          </p>
                        );
                      })}
                    </div>
                  </InfiniteScroll>
                </div>
              )}
            </div>

            <div className="pt-2 md:pt-0">
              <button className=" border py-2 px-6 bg-green-500 text-white rounded cursor-pointer">
                Submit
              </button>
            </div>
          </div>
        </form>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-x-3 mt-4">
            <div className="flex flex-col w-full">
              <label className="pb-1 ps-2">Student Name</label>
              <input
                type="text"
                className="border w-full py-2 px-4 rounded"
                placeholder="Student Name"
                defaultValue={userDetails?.name}
              />
            </div>

            <div className="flex flex-col w-full pt-2 md:pt-0">
              <label className="pb-1 ps-2">Student E-mail</label>
              <input
                type="text"
                className="border w-full py-2 px-4 rounded"
                placeholder="Student E-mail"
                defaultValue={userDetails?.email}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-x-3 mt-4">
            <div className="flex flex-col w-full">
              <label className="pb-1 ps-2">Student NID</label>
              <input
                type="text"
                className="border w-full py-2 px-4 rounded"
                placeholder="Student NID"
                defaultValue={userDetails?.nid_no}
              />
            </div>

            <div className="flex flex-col w-full pt-2 md:pt-0">
              <label className="pb-1 ps-2">Student Mobile</label>
              <input
                type="text"
                className="border w-full py-2 px-4 rounded"
                placeholder="Student Mobile"
                defaultValue={userDetails?.mobile}
              />
            </div>
          </div>

          <div className="flex flex-col w-full md:w-1/2 pt-3">
            <Dropdown
              value={userRoleId}
              onChange={(e) => setUserRoleId(e.value)}
              options={userRoles}
              optionLabel="label"
              placeholder="Select a Type"
              className="w-full md:w-14rem"
            />
          </div>

          <div>
            <input
              className="border mt-4 py-2 px-6 bg-green-500 text-white rounded cursor-pointer"
              type="submit"
              value="Submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateAdmin;
