import React, { useEffect, useState } from "react";
import useData from "../../Context/useData";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Loader from "../Loader/Loader";
import { baseUrl } from "../../helper";
import { toast } from "react-toastify";

const CommisionTable = () => {
  const {  token, loader } = useData().user;
  const [balanceDetails, setBalanceDetails] = useState(null);
  const [limitData, setLimitData] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [totalAmount, setTotalAmount] = useState("")
  let pages = pageNumber / limitData + 1;

  useEffect(() => {
    if (!token) {
      return;
    }

    const getAllComissionList = async () => {
      setLoading(true);
      try {
        const res = await fetch(`${baseUrl.url}/user-balance-details?page=${pages.toFixed(0)}`, {
          method: "Get",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          console.log("res--->", resData.total);
          setBalanceDetails(resData.data?.data);
          setTotalData(resData.data.total);
          setTotalAmount(resData.total)
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    getAllComissionList();
  }, [token, totalData, pageNumber, limitData, pages]);


  // let totalBalance = balanceDetails?.reduce((sum, item) => {
  //   const balance = parseFloat(item.balance);
  //   return balance !== null && !isNaN(balance) ? sum + balance : sum;
  // }, 0);


  const NameTem  = (data)=>{
    return <p>{data?.name}</p>
  }

  const EmailTem= (data)=>{
    return <p>{data?.email}</p>
  }

  const UserTem = (data)=>{
    return <p>{data?.login_id}</p>
  }

  const BalanceTem = (data)=>{
    return <p>{data?.balance}</p>
  }

  return (
    <section className="py-10 px-4">
      <div>
        <h4 className="text-2xl font-medium">All Commissions User</h4>
        <p className="text-lg">Total Market Due :{totalAmount} </p>
      </div>
      <div className="mt-4">
        {loader ? (
          <Loader />
        ) : (
          <DataTable
            value={balanceDetails}
            emptyMessage="No Data Found"
            size="small"
            first={pageNumber}
            paginator
            rows={limitData}
            totalRecords={totalData}
            loading={loading}
            rowHover={true}
            lazy
            onPage={(e) => {
              setPageNumber(e.first);
              setLimitData(e.rows);
            }}


            tableStyle={{ minWidth: "50rem" }}

          >
             <Column field="name" body={NameTem} header="Name"></Column>
            <Column field="email" body={EmailTem} header="email"></Column>
            <Column field="User Id" header="login_id" body={UserTem}></Column>
            <Column field="Balance" header="balance" body={BalanceTem}></Column>
          </DataTable>
        )}
      </div>
    </section>
  );
};

export default CommisionTable;
