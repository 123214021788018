import React, { useEffect, useState } from "react";
import useData from "../../Context/useData";
import { baseUrl } from "../../helper";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import InfiniteScroll from "react-infinite-scroll-component";
import { MdKeyboardArrowDown } from "react-icons/md";
import Loader from "../Loader/Loader";

const CreateAccount = () => {
  const { token, allCourse } = useData().user;
  const [id, setID] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [refId, setRefId] = useState("");
  const [refDetails, setRefDetails] = useState(null);
  const [placeID, setPlaceID] = useState("");
  const [placeMentDetails, setPlacementDetails] = useState(null);
  const [PositionID, setPositionID] = useState("");
  const [course, setCourse] = useState(null);
  const [userList, setUserLIst] = useState([]);
  const [totalData, setTotalData] = useState("");
  const [pageNumber, setPageNumber] = useState("1");
  const [show, setShow] = useState(false);
  const [showRef, setShowRef] = useState(false);
  const [showPlacement, setShowPlacement] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchUser, setSearchUser] = useState("");

  // get all user
  useEffect(() => {
    if (!token) {
      return;
    }
    const getProfile = async () => {
      try {
        setLoader(true);
        const res = await fetch(
          `${baseUrl.url}/all/user?page=${+pageNumber}${
            searchUser && `&login_id=${searchUser}`
          }`,
          {
            method: "Get",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const resData = await res.json();
        if (res.ok) {
          setUserLIst([...userList, ...resData?.data?.data]);
          setTotalData(resData.data?.total);
          if (searchUser) {
            setUserLIst(resData.data?.data);
            if (searchUser.length > 4 && !resData.data?.data.length) {
              toast.error("N0 User Found");
            }
          }
        }
      } catch (e) {
        console.error(e.message);
      } finally {
        setLoader(false);
      }
    };
    getProfile().then();
  }, [token, pageNumber, searchUser]);

  const userId = userList?.map((user) => {
    let obj = {};
    obj.label = user?.login_id;
    obj.value = user?.id;
    return obj;
  });
  const courses = allCourse?.map((data) => {
    let obj = {};
    obj.label = data?.title;
    obj.value = data?.id;
    return obj;
  });

  // get user details 1st by user Id
  const handleUser = (e) => {
    e.preventDefault();
    if (!id && !token) {
      return;
    }
    const userDetails = async () => {
      const res = await fetch(`${baseUrl.url}/user/${id?.value}`, {
        method: "get",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        setUserDetails(resData.data);
        // console.log("done1");
      }
    };
    userDetails().then();
  };

  // get refer details 2nd
  const handleRefer = (e) => {
    e.preventDefault();
    if (!refId && !token) {
      return;
    }
    const userDetails = async () => {
      const res = await fetch(`${baseUrl.url}/user/${refId.value}`, {
        method: "get",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        setRefDetails(resData.data);
      }
    };
    userDetails().then();
  };

  // get placement details 3rd
  const handlePlacement = (e) => {
    e.preventDefault();
    if (!placeID && !token) {
      return;
    }
    const userDetails = async () => {
      const res = await fetch(`${baseUrl.url}/user/${placeID.value}`, {
        method: "get",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        // (resData.data)
        setPlacementDetails(resData.data);
      }
    };
    userDetails().then();
  };

  const Position = [
    { value: 1, label: "A" },
    { value: 2, label: "B" },
  ];

  const handleCreateUser = (e) => {
    e.preventDefault();
    const form = e.target;
    const netOffice = form.netOffice.value;
    // console.log("net", netOffice);

    const postData = {
      student_login_id: userDetails?.login_id,
      ref_login_id: refDetails?.login_id,
      placement_login_id: placeMentDetails?.login_id,
      position: PositionID,
      net_office: netOffice,
      course_id: course,
    };

    const postAccount = async () => {
      if (!postData?.student_login_id) {
        return toast.error("Please select a User");
      }

      if (!postData?.ref_login_id) {
        return toast.error("Please select a Refer User");
      }
      if (!postData?.placement_login_id) {
        return toast.error("Please select a Placement User");
      }
      if (!postData?.position) {
        return toast.error("Please select a Position");
      }
      if (!postData?.net_office) {
        return toast.error("please Enter a Net Office");
      }

      let formData = new FormData();
      for (const i in postData) {
        const element = postData[i];
        formData.append(`${i}`, element);
      }

      const res = await fetch(`${baseUrl.url}/student/ref`, {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const resData = await res.json();
      if (res.ok) {
        toast.success(resData.message);
        form.reset();
        setID(null);
        setRefId(null);
        setPlaceID(null);
        setPositionID(null);
        setUserDetails(null);
        setRefDetails(null);
        setPlacementDetails(null);
        setSearchUser("");
      } else {
        toast.error(resData.message);
      }
    };
    postAccount().then();
  };

  if (!searchUser && loader) {
    return <Loader />;
  }
  return (
    <div className="py-10 px-4 common">
      <h2 className="text-3xl font-medium">Sign Up</h2>
      <div className="mt-5">
        <div className="pb-10">
          <form
            onSubmit={handleUser}
            className="grid grid-cols-1 md:grid-cols-2 items-center gap-x-5 "
          >
            <div>
              <div className="flex justify-between items-center border p-2">
                <input
                  type="text"
                  className="w-full border-none myInput"
                  placeholder="Select Student"
                  defaultValue={id?.label}
                  onChange={(e) => setSearchUser(e.target.value)}
                  onClick={() => setShow(!show)}
                />
                <MdKeyboardArrowDown size={20} />
              </div>
              {show && (
                <div
                  id="scrollableDiv"
                  className="mt-3"
                  style={{ height: "200px", overflowY: "scroll" }}
                >
                  <InfiniteScroll
                    dataLength={userList?.length}
                    next={() => {
                      setPageNumber((prevPage) => +prevPage + 1);
                    }}
                    inverse={false}
                    hasMore={totalData > userList?.length}
                    loader={<h4>Loading...</h4>}
                    className="message"
                    scrollableTarget="scrollableDiv"
                  >
                    <div className="border">
                      {userId?.map((user_uid, i) => {
                        // console.log("useriD", user_uid);
                        return (
                          <p
                            key={i}
                            onClick={() => {
                              setID(user_uid);
                              setShow(false);
                              setSearchUser("");
                            }}
                            className="px-2 py-1"
                          >
                            {user_uid?.label}
                          </p>
                        );
                      })}
                    </div>
                  </InfiniteScroll>
                </div>
              )}
            </div>
            <div className="pt-2 md:pt-0">
              <button className=" border py-2 px-6 bg-green-500 text-white rounded cursor-pointer ">
                Submit
              </button>
            </div>
          </form>
          {/* student id end */}
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-x-3 mt-4">
              <div className="flex flex-col w-full">
                <label className="pb-1 ps-2">Student Name</label>
                <input
                  type="text"
                  className="border w-full py-2 px-4 rounded"
                  placeholder="Student Name"
                  defaultValue={userDetails?.name}
                />
              </div>

              <div className="flex flex-col w-full pt-2 md:pt-0">
                <label className="pb-1 ps-2">Student E-mail</label>
                <input
                  type="text"
                  className="border w-full py-2 px-4 rounded"
                  placeholder="Student E-mail"
                  defaultValue={userDetails?.email}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-x-3 mt-4">
              <div className="flex flex-col w-full">
                <label className="pb-1 ps-2">Student NID</label>
                <input
                  type="text"
                  className="border w-full py-2 px-4 rounded"
                  placeholder="Student NID"
                  defaultValue={userDetails?.nid_no}
                />
              </div>

              <div className="flex flex-col w-full">
                <label className="pb-1 ps-2">Student Mobile</label>
                <input
                  type="text"
                  className="border w-full py-2 px-4 rounded"
                  placeholder="Student Mobile"
                  defaultValue={userDetails?.mobile}
                />
              </div>
            </div>
          </div>
        </div>
        {/* student info end */}

        {/*Ref*/}
        <div className="pb-10">
          <form
            onSubmit={handleRefer}
            className="grid grid-cols-1 md:grid-cols-2 items-center gap-x-5 mt-4"
          >
            <div>
              <div className="flex justify-between items-center border p-2">
                <input
                  type="text"
                  className="w-full border-none myInput"
                  placeholder="Select Reference Student ID"
                  defaultValue={refId?.label}
                  onChange={(e) => setSearchUser(e.target.value)}
                  onClick={() => setShowRef(!showRef)}
                />
                <MdKeyboardArrowDown size={20} />
              </div>

              {showRef && (
                <div
                  id="scrollableDiv"
                  className="mt-3"
                  style={{ height: "200px", overflowY: "scroll" }}
                >
                  <InfiniteScroll
                    dataLength={userList?.length}
                    next={() => {
                      setPageNumber((prevPage) => +prevPage + 1);
                    }}
                    inverse={false}
                    hasMore={totalData > userList?.length}
                    loader={<h4>Loading...</h4>}
                    className="message"
                    scrollableTarget="scrollableDiv"
                  >
                    <div className="border">
                      {userId?.map((user_uid, i) => {
                        // console.log("useriD", user_uid);
                        return (
                          <p
                            key={i}
                            onClick={() => {
                              setRefId(user_uid);
                              setShowRef(false);
                              setSearchUser("");
                              // setUpdate(!update);
                            }}
                            className="px-2 py-1"
                          >
                            {user_uid?.label}
                          </p>
                        );
                      })}
                    </div>
                  </InfiniteScroll>
                </div>
              )}
            </div>
            <div className="pt-2 md:pt-0">
              <button className=" border py-2 px-6 bg-green-500 text-white rounded cursor-pointer">
                Submit
              </button>
            </div>
          </form>

          <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-x-3 mt-4">
            <div className="flex flex-col w-full">
              <label className="pb-1 ps-2">Reference Name</label>
              <input
                type="text"
                className="border w-full py-2 px-4 rounded"
                placeholder="Reference Name"
                defaultValue={refDetails?.name}
              />
            </div>

            <div className="flex flex-col w-full pt-5 md:pt-0">
              <label className="pb-1 ps-2">Reference Mobile</label>
              <input
                type="text"
                className="border w-full py-2 px-4 rounded"
                placeholder="Reference Mobile"
                defaultValue={refDetails?.mobile}
              />
            </div>
          </div>
        </div>

        <form
          onSubmit={handlePlacement}
          className="grid grid-cols-1 md:grid-cols-2 items-center gap-x-5 mt-4"
        >
          {/*<Select*/}
          {/*    defaultMenuIsOpen={true}*/}
          {/*    // isLoading={loading}*/}
          {/*    options={userList}*/}
          {/*    components={{*/}
          {/*      MenuList,*/}
          {/*    }}*/}
          {/*/>*/}
          <div>
            <div className="flex justify-between items-center border p-2">
              <input
                type="text"
                className="w-full border-none myInput"
                placeholder="Select Placement Student ID"
                defaultValue={placeID?.label}
                onChange={(e) => setSearchUser(e.target.value)}
                onClick={() => setShowPlacement(!showPlacement)}
              />
              <MdKeyboardArrowDown size={20} />
            </div>
            {showPlacement && (
              <div
                id="scrollableDiv"
                className="mt-3"
                style={{ height: "200px", overflowY: "scroll" }}
              >
                <InfiniteScroll
                  dataLength={userList?.length}
                  next={() => {
                    setPageNumber((prevPage) => +prevPage + 1);
                  }}
                  inverse={false}
                  hasMore={totalData > userList?.length}
                  loader={<h4>Loading...</h4>}
                  className="message"
                  scrollableTarget="scrollableDiv"
                >
                  <div className="border">
                    {userId?.map((user_uid, i) => {
                      // console.log("useriD", user_uid);
                      return (
                        <p
                          key={i}
                          onClick={() => {
                            setPlaceID(user_uid);
                            setShowPlacement(false);
                            setSearchUser("");
                            // setUpdate(!update);
                          }}
                          className="px-2 py-1"
                        >
                          {user_uid?.label}
                        </p>
                      );
                    })}
                  </div>
                </InfiniteScroll>
              </div>
            )}
          </div>

          <div className="pt-2 md:pt-0">
            <button className=" border py-2 px-6 bg-green-500 text-white rounded cursor-pointer">
              Submit
            </button>
          </div>
        </form>

        <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-x-3 mt-4">
          <div className="flex flex-col w-full">
            <label className="pb-1 ps-2">Placement Name</label>
            <input
              type="text"
              className="border w-full py-2 px-4 rounded"
              placeholder="Placement Name"
              defaultValue={placeMentDetails?.name}
            />
          </div>

          <div className="flex flex-col w-full pt-5 md:pt-0">
            <label className="pb-1 ps-2">Placement Mobile</label>
            <input
              type="text"
              className="border w-full py-2 px-4 rounded"
              placeholder="Placement Mobile"
              defaultValue={placeMentDetails?.mobile}
            />
          </div>
        </div>
        {/* placement Info */}

        <form onSubmit={handleCreateUser}>
          <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-x-3 mt-4">
            <div className="flex flex-col w-full">
              <label className="pb-1 ps-2">Position</label>
              <Dropdown
                value={PositionID}
                onChange={(e) => setPositionID(e.value)}
                options={Position}
                optionLabel="label"
                placeholder="Position"
                className="w-full"
              />
            </div>
            <div className="flex flex-col w-full pt-5 md:pt-0">
              <label className="pb-1 ps-2">Net Office</label>
              <input
                type="text"
                className="border w-full py-2 px-4 rounded"
                placeholder="Net Office"
                name="netOffice"
              />
            </div>
          </div>
          <div className="flex flex-col w-1/2 pt-2">
            <label className="pb-1 ps-2">Select Course</label>
            <Dropdown
              value={course}
              onChange={(e) => setCourse(e.value)}
              options={courses}
              optionLabel="label"
              placeholder="Select Course"
              className="w-full"
            />
          </div>

          <div className="">
            <input
              className="border mt-4 py-2 px-6 bg-green-500 text-white rounded cursor-pointer"
              type="submit"
              value="Submit"
            />
          </div>
        </form>
      </div>
      {/* end */}
    </div>
  );
};

export default CreateAccount;
