import React from "react";
import { HiBars3 } from "react-icons/hi2";
import { Avatar } from "primereact/avatar";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { baseUrl, removeToken } from "../../helper";
import useData from "../../Context/useData";
import { useNavigate } from "react-router-dom";
import icon from "../../assets/icon.jpg";
import { toast } from "react-toastify";

const Header = ({ show, setShow }) => {
  const { token } = useData().user;

  // myProfile
  const navigate = useNavigate();

  const handleLogOut = async () => {
  try {
    const res = await fetch(`${baseUrl.url}/logout`, {
      method: "Post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.ok) {
      removeToken("token");
      navigate("/login");
    }
  } catch (error) {
    toast.error(error.message)
  }
  };


  return (
    <div className=" shadow-lg  bg-white">
      <div className=" py-2 px-4 flex justify-between items-center">
        <div className="h-8 w-8 flex items-center justify-center">
          <HiBars3
            onClick={() => setShow(!show)}
            size={30}
            className="cursor-pointer"
          />
        </div>

        <div className="h-16 w-32">
          <img className="w-full h-full" src={icon} alt="logo" />
        </div>

        <div>
          <Menu
            menuButton={
              <MenuButton>
                <Avatar icon="pi pi-user"
                //  image={`https://api.vitcash.com/api/${myProfile?.image}`}
                  shape="circle" />

              
              </MenuButton>
            }
            transition
          >
            <MenuItem onClick={handleLogOut} className="text-base font-medium">
              Log out
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Header;
