import React, { useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import noImg from "../../assets/fileView.jpg";
import { AiOutlinePlus } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { baseUrl } from "../../helper";
import useData from "../../Context/useData";
import { toast } from "react-toastify";

const CreateCourse = () => {
  const { token } = useData().user;
  const [description, setDescription] = useState("");
  const [imgUpload, setImgUpload] = useState();
  const [changeValue, setChangeValue] = useState({});
  const [lectures, setLectures] = useState([{ id: 1, content: "" }]);

  const imgChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgUpload(e.target.files[0]);
    }
  };

  const setTeamValue = (id, value, no) => {
    const filteredData = lectures.filter((t) => t.id !== id);
    let data = lectures.find((t) => t.id === id);
    data = { ...data, [no]: value };
    const newData = [...filteredData, data];
    setLectures(newData);
  };

  const handleMinus = (id) => {
    let FilterData = lectures?.filter((f) => f.id !== id);
    setLectures(FilterData);
  };

  const setInputValue = (e) => {
    setChangeValue({
      ...changeValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    const postData = {
      ...changeValue,
      image: imgUpload,
      description,
      content: lectures,
    };
    let formData = new FormData();
    for (const i in postData) {
      const element = postData[i];
      if (i === "content") {
        for (const j in element) {
          const element2 = element[j];
          formData.append(`${i}[]`, element2.content);
        }
      } else {
        formData.append(`${i}`, element);
      }
    }

    const postAdmin = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/course-all`, {
          method: "post",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

        const resData = await res.json();
        if (res.ok) {
          toast.success(resData.message);
          form.reset();
          setDescription(null);
        } else {
          for (const field in resData.message) {
            if (resData.message.hasOwnProperty(field)) {
              resData.message[field].forEach((errorMessage) => {
                toast.error(`${errorMessage}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
            }
          }
        }
      } catch (error) {
        toast.error(error?.message);
      }
    };
    postAdmin();
  };

  return (
    <section className="py-10 px-4">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-8">
            <div>
              <div className="grid grid-cols-2 gap-2">
                <div className="flex flex-col">
                  <label className="pb-2">Type Course Name</label>
                  <input
                    type="text"
                    className="border border-gray-700 rounded py-2 ps-3"
                    name="title"
                    placeholder="Type Course Name"
                    onChange={(e) => setInputValue(e)}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="pb-2">Type Price </label>
                  <input
                    type="number"
                    className="border border-gray-700 rounded py-2 ps-3"
                    name="price"
                    placeholder="Type Price"
                    onChange={(e) => setInputValue(e)}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-2 pt-2">
                <div className="flex flex-col">
                  <label className="pb-2">Type Discount Price</label>
                  <input
                    type="number"
                    className="border border-gray-700 rounded py-2 ps-3"
                    name="discount"
                    placeholder="Type Discount Price"
                    onChange={(e) => setInputValue(e)}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="pb-2">Type Rating Number </label>
                  <input
                    type="number"
                    className="border border-gray-700 rounded py-2 ps-3"
                    name="rating"
                    placeholder="Type Rating Number"
                    onChange={(e) => setInputValue(e)}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-2 pt-2">
                <div className="flex flex-col">
                  <label className="pb-2">Type Student Enrollment Number</label>
                  <input
                    type="number"
                    className="border border-gray-700 rounded py-2 ps-3"
                    name="enroll"
                    placeholder="Type Discount Price"
                    onChange={(e) => setInputValue(e)}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="pb-2">Type Student Review Number </label>
                  <input
                    type="number"
                    className="border border-gray-700 rounded py-2 ps-3"
                    name="comment"
                    placeholder="Type Rating Number"
                    onChange={(e) => setInputValue(e)}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-2 pt-2">
                <div className="flex flex-col">
                  <label className="pb-2">Type Quizzes Number</label>
                  <input
                    type="number"
                    className="border border-gray-700 rounded py-2 ps-3"
                    name="quizzes"
                    placeholder="Type Discount Price"
                    onChange={(e) => setInputValue(e)}
                  />
                </div>
              </div>
              <div className="flex flex-col my-2">
                <label className="pb-2">Type Course Description</label>
                <InputTextarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={10}
                  className="w-full"
                />
              </div>

              <div className="flex flex-col mb-2">
                <label className="pb-2">Select Image</label>
                <div className="w-full h-60 border rounded relative">
                  <img
                    src={`${
                      imgUpload ? URL.createObjectURL(imgUpload) : `${noImg}`
                    }`}
                    alt=""
                    className=" h-full w-full rounded"
                  />
                  <input
                    type="file"
                    className={`absolute top-0 left-0 w-48 h-full rounded opacity-0`}
                    accept={`"image/*"`}
                    onChange={imgChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div>
              <div
                className={`${
                  lectures?.length > 3 ? "overflow-y-scroll h-80" : ""
                } mb-2`}
              >
                {lectures?.map((t, i) => (
                  <div key={i}>
                    <div className="flex justify-end ">
                      <RxCross1
                        onClick={() => handleMinus(t.id)}
                        className="flex justify-end cursor-pointer"
                        size={16}
                      />
                    </div>

                    <input
                      className="ps-3 py-1.5 border border-gray-300 rounded-lg inputShadow w-full"
                      onChange={(e) =>
                        setTeamValue(t.id, e.target.value, "content")
                      }
                    />
                  </div>
                ))}
              </div>

              <div>
                <label htmlFor="" className="flex gap-3 items-center mt-4">
                  <span className="text-base   font-[500]">Content</span>
                  <AiOutlinePlus
                    size={25}
                    onClick={() => {
                      const newData = [
                        ...lectures,
                        {
                          id: lectures?.length + 1,
                          content: "",
                        },
                      ];
                      setLectures(newData);
                    }}
                    className="p-0.5 bg-[#34c38f] hover:bg-[#2ca67a] text-white font-semibold text-center rounded-md cursor-pointer"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>

        <div>
          <input
            className="border mt-4 py-2 px-6 bg-green-500 text-white rounded cursor-pointer"
            type="submit"
            value="Submit"
          />
        </div>
      </form>
    </section>
  );
};

export default CreateCourse;
