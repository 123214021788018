import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { baseUrl } from "../../helper";
import useData from "../../Context/useData";
import { toast } from "react-toastify";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import Loader from "../Loader/Loader";

const CreateBatchModal = ({ modalVisible }) => {
  const { visible, setVisible, previousData } = modalVisible;
  console.log("previousData", previousData);

  const { token, allCourse } = useData().user;
  const [loader, setLoader] = useState(false);
  const [weekDay, setWeekDay] = useState(null);

  const [batchName, setBatchName] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [time, setTime] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);

  const courses = allCourse?.map((course) => {
    let obj = {};
    obj.label = course.title;
    obj.value = course?.id;
    return obj;
  });

  const weekDays = weekDay?.map((day) => {
    let obj = {};
    obj.label = day.name;
    obj.value = day?.id;
    return obj;
  });

  useEffect(() => {
    if (!token) {
      return;
    }

    const getWithdrawList = async () => {
      setLoader(true);
      try {
        const res = await fetch(`${baseUrl.url}/get-week-days`, {
          method: "Get",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setWeekDay(resData.data);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoader(false);
      }
    };
    getWithdrawList();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!batchName) {
      toast.warn("Please Provided Batch Name ");
    }

    if (!courseId) {
      toast.warn("please Select a Course");
    }

    if (!time) {
      toast.warn("Please Select Time");
    }
    if (!selectedDays) {
      return toast.warn("Please Provided Class Day");
    }

    let formData = new FormData();
    formData.append("name", batchName);
    formData.append("course_id", courseId);
    formData.append("time", moment(time).format("h:mma"));
    selectedDays.forEach((day) => {
      formData.append("week_day[]", day);
    });

    try {
      const res = await fetch(`${baseUrl.url}/batch`, {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const resData = await res.json();
      if (res.ok) {
        toast.success(resData.message);
        setBatchName(null);
        setCourseId(null);
        setTime(null);
        setSelectedDays(null);
        setVisible(false);
      } else {
        const resData = await res.json();
        toast.error(resData.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  if (loader) {
    return <Loader />;
  }
  return (
    <div>
      <Dialog
        header="Create Batch"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: "35vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-y-1">
            <label className="">Batch Name</label>
            <InputText
              className="w-full"
              value={batchName}
              onChange={(e) => setBatchName(e.target.value)}
            />
          </div>

          <div className="flex flex-col gap-y-1">
            <label className="">Select Course</label>
            <Dropdown
              value={courseId}
              onChange={(e) => setCourseId(e.value)}
              options={courses}
              optionLabel="label"
              placeholder="Select a City"
              className="w-full md:w-14rem"
            />
          </div>

          <div className="flex flex-col gap-y-1 mt-2">
            <label>Time</label>
            <Calendar
              value={time}
              onChange={(e) => setTime(e.value)}
              hourFormat="12"
              timeOnly
            />
          </div>

          <div className="flex flex-col gap-y-1 mt-2">
            <label>Select Class Days</label>

            <MultiSelect
              value={selectedDays}
              options={weekDays}
              onChange={(e) => {
                setSelectedDays(e.value);
              }}
              placeholder="Select days"
              display="chip"
              optionLabel="label"
            />
          </div>

          <div className="mt-4">
            <Button severity="success" size="small" label="Submit" />
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default CreateBatchModal;
