import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import useData from "../../Context/useData";
import { baseUrl } from "../../helper";
import { toast } from "react-toastify";

const CommissionWithdraw = () => {
  const { token, myProfile } = useData().user;
  console.log(myProfile);
  const [payMethods, setPayMethods] = useState(null);
  const [amount, setAmount] = useState(null);
  const [number, setNumber] = useState(null)

  const methods = [
    { label: "Bkash", value: "Bikash" },
    { label: "Nagad", value: "Nagad" },
    { label: "Rocket", value: "Rocket" },
  ];

  const handleWithdraw = async (e) => {
    e.preventDefault();
    if (!token) {
      return;
    }

    const data = {
      amount:+amount,
      payment_type: payMethods,
      phone:number
    };

    const postAccount = async () => {
      const res = await fetch(`${baseUrl.url}/withdraw/request`, {
        method: "post",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      const resData = await res.json();
      if (res.ok) {
        console.log(resData);
        toast.success(resData.message);
        // form.reset();
      } else {
        toast.error(res.message);
      }
    };
    postAccount();
  };

  return (
    <div className="text-slate-500 pt-10 px-4">
      <h1 className="text-lg font-medium">
        Create Withdrawal! Balance: {myProfile?.balance}
      </h1>

      <form
        onSubmit={handleWithdraw}
        className="mt-5 common"
      >
        <div className="grid grid-cols-2 gap-2 items-center">
          <div className="flex flex-col gap-1 w-full">
            <label className="pb-1 ps-2">Amount</label>
            <input
              type="number"
              className="border w-full py-2 px-4 rounded"
              placeholder="Amount"
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-1 w-full">
            <label className="pb-1 ps-2">Phone Number</label>
            <input
              type="number"
              className="border w-full py-2 px-4 rounded"
              placeholder="Number"
              onChange={(e) => setNumber(e.target.value)}
            />
          </div>
        </div>

        <div className="flex flex-col gap-1 mt-3 w-1/2">
          <label>Select Payment Methods</label>
          <Dropdown
            value={payMethods}
            onChange={(e) => setPayMethods(e.value)}
            options={methods}
            optionLabel="label"
            showClear
            placeholder="Select Payment Methods"
            className="w- md:w-14rem"
          />
        </div>

        <div className="">
          <input
            className="border mt-4 py-2 px-6 bg-green-500 text-white rounded cursor-pointer"
            type="submit"
            value="Submit"
          />
        </div>
      </form>
    </div>
  );
};

export default CommissionWithdraw;
