import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import { BsFillCreditCardFill } from "react-icons/bs";
import useData from "../../Context/useData";
import { baseUrl } from "../../helper";
import { toast } from "react-toastify";

const BalanceTransfer = () => {
  const { allUsers, token } = useData().user;
  const [selectUser, setSelectUser] = useState(null);
  const [changeValue, setChangeValue] = useState({});

  const userId = allUsers?.map((user) => {
    let obj = {};
    obj.label = user?.login_id;
    obj.value = user?.id;
    return obj;
  });

  const setValue = (e) => {
    setChangeValue({
      ...changeValue,
      [e.target.name]: e.target.value,
    });
  };

  const handlePost = (e) => {
    e.preventDefault();
    const form = e.target;

    const newData = {
      amount: changeValue.amount,
      pin: changeValue.pin,
      note: changeValue.note,
      to: selectUser,
    };

    console.log("new", newData);

    let formData = new FormData();
    for (const i in newData) {
      const element = newData[i];
      formData.append(`${i}`, element);
    }

    const postData = async () => {
      try {
        let res = await fetch(`${baseUrl.url}/balance/transfer`, {
          method: "post",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        if (res.ok) {
          toast.success("successfully updated");
          form.reset();
        } else {
          const resData = await res.json();
          toast.error(resData.message);
          form.reset();
        }
      } catch (error) {
        console.error(error?.message)
      }
    };
    postData()
  };

  return (
    <div className="text-slate-500 pt-10 px-4">

      <h1 className="text-lg font-medium ">
        Create Withdrawal! Balance: 70.00
      </h1>

      <form onSubmit={handlePost} className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pt-5">
        <div className=" flex flex-col gap-y-1">
          <label className="ps-1">Amount</label>
          <input
            type="number"
            className=" w-full py-2 ps-1 rounded focus:outline-0 border"
            onChange={(e) => setValue(e)}
            placeholder="Amount"
            name="amount"
          />
        </div>

        <div className=" flex flex-col gap-y-1 common">
          <label className="ps-1">Select User</label>
          <Dropdown
            value={selectUser}
            onChange={(e) => setSelectUser(e.value)}
            options={userId}
            optionLabel="label"
            placeholder="Select a Country"
            filter
          />
        </div>

        <div className=" flex flex-col gap-y-1 pt-2">
          <label className="ps-1">Pin</label>
          <input
            type="number"
            className=" w-full py-2 ps-1 rounded focus:outline-0 border"
            placeholder="Pin"
            onChange={(e) => setValue(e)}
            name="pin"
          />
        </div>

        <div className=" flex flex-col gap-y-1 pt-2">
          <label className="ps-1">Note</label>
          <input
            type="text"
            className=" w-full py-2 ps-1 rounded focus:outline-0 border"
            placeholder="Note"
            name="note"
            onChange={(e) => setValue(e)}
          />
        </div>

        <div>
          <button className="bg-green-500 px-4 py-2 rounded text-white flex gap-x-2 items-center mt-5">
            <BsFillCreditCardFill size={20} /> <span> Transfer</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default BalanceTransfer;
