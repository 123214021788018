import React from "react";
import useData from "../Context/useData";
import { Navigate, useLocation } from "react-router-dom";
import Loader from "../Components/Loader/Loader";
const PrivateRoute = ({ children }) => {
  const { token, loader } = useData().user;
  const location = useLocation();


  if (loader) {
    return  <Loader/>;
  }
  
  // console.log("token", token);
  if (!token) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};

export default PrivateRoute;
