import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import useData from "../../Context/useData";
import { baseUrl } from "../../helper";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";

import { AiOutlineDelete } from "react-icons/ai";

const Designation = () => {
  const { token } = useData().user;
  const [allDesignation, setAllDesignation] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [changeValue, setChangeValue] = useState({});
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (!token) {
      return;
    }
    const getUser = async () => {
      setLoader(true);
      const res = await fetch(`${baseUrl.url}/designation`, {
        method: "Get",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        setLoader(false);
        setAllDesignation(resData.data);
      }
    };
    getUser();
  }, [token, update]);

  const setInputValue = (e) => {
    setChangeValue({
      ...changeValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;

    const postData = {
      name: changeValue?.name,
      total_conection_start: changeValue.total_conection_start,
      total_conection_end: changeValue?.total_conection_end,
    };

    const postAdmin = async () => {
      setLoader(true);
      try {
        const res = await fetch(`${baseUrl.url}/designation`, {
          method: "post",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(postData),
        });

        const resData = await res.json();
        if (res.ok) {
          toast.success(resData.message);
          setShowModal(false);
          setUpdate(true);
          form.reset();
        } else {
          form.reset();
          toast.error(resData.message);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoader(false);
      }
    };
    postAdmin();
  };

  const designationNameTem = (data) => {
    return <p>{data?.name}</p>;
  };

  const connectionStartTem = (data) => {
    return <p>{data?.total_conection}</p>;
  };

  const connectionEndTem = (data) => {
    return <p>{data?.total_conection_end}</p>;
  };

  const handleDelete = async (id) => {
    if (!token) {
      return;
    }
    try {
      const res = await fetch(`${baseUrl.url}/designation/${id}`, {
        method: "delete",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.ok) {
        setUpdate(!update);
        toast.success("Data Deleted SuccessFully");
      } else {
        const resData = await res.json();
        toast.warn(resData?.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const actionTem = (data) => {
    const id = data?.id;
    return (
      <div className=" flex items-center gap-x-2">
        {/* <div className="bg-green-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer">
          <AiFillEdit className="text-white" />
        </div> */}

        <div
          className="bg-red-400 h-8 w-8 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => handleDelete(id)}
        >
          <AiOutlineDelete className="text-white" />
        </div>
      </div>
    );
  };

  if (loader) {
    return <Loader />;
  }

  return (
    <section className="py-10 px-4">
      <div className="flex justify-between items-center mb-3">
        <h3 className="text-lg">All Events</h3>

        <div>
          <button
            onClick={() => setShowModal(true)}
            className="px-4 py-2 bg-green-600 text-white rounded"
          >
            Event
          </button>
        </div>

        {/* <Button label="Show" icon="pi pi-external-link"  /> */}
      </div>

      <div className="card">
        <DataTable
          emptyMessage="No Event Data Fount"
          value={allDesignation}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            field="code"
            header="Designation Name"
            body={designationNameTem}
            sortable
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="name"
            header="Connection Start"
            body={connectionStartTem}
            style={{ width: "25%" }}
          ></Column>

          <Column
            field="name"
            header="Connection End"
            body={connectionEndTem}
            style={{ width: "25%" }}
          ></Column>

          <Column
            field="name"
            header="Action"
            body={actionTem}
            style={{ width: "25%" }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        header="Create New Event"
        visible={showModal}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setShowModal(false)}
        draggable={false}
        resizable={false}
      >
        <form onSubmit={handleSubmit} className="common">
          <div className="mt-2 flex flex-col">
            <label className="pb-1">Enter a Designation Name</label>
            <input
              type="text"
              className="border border-gray-700 rounded py-2 ps-3"
              name="name"
              placeholder="Designation Name"
              onChange={(e) => setInputValue(e)}
            />
          </div>

          <div className="mt-2 flex flex-col">
            <label className="pb-1">Enter a Total Connections Start</label>
            <input
              type="number"
              className="border border-gray-700 rounded py-2 ps-3"
              name="total_conection_start"
              placeholder="Enter a Total Connections Start"
              onChange={(e) => setInputValue(e)}
            />
          </div>
          <div className="mt-2 flex flex-col">
            <label className="pb-1">Enter a Total Connections End</label>
            <input
              type="number"
              className="border border-gray-700 rounded py-2 ps-3"
              name="total_conection_end"
              placeholder="Enter a Total Connections End"
              onChange={(e) => setInputValue(e)}
            />
          </div>
          <input
            className="border mt-4 py-2 px-6 bg-green-500 text-white rounded cursor-pointer"
            type="submit"
            value="Submit"
          />
        </form>
      </Dialog>
    </section>
  );
};

export default Designation;
