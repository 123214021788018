import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import useData from "../../Context/useData";
import { baseUrl } from "../../helper";
// import { MdTry } from "react-icons/md";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

const MyAffiliate = () => {
  const { token, myProfile } = useData().user;
  const [loader, setLoader] = useState(false);
  const [myAffiliates, setMyAffiliates] = useState();
  const [visible, setVisible] = useState(false);
  const [userId, setUserId] = useState("");
  const [searchTeam, setSearchTeam] = useState("");

  // student_refer history
  useEffect(() => {
    if (!token) {
      return;
    }

    const getHistory = async () => {
      setLoader(true);
      try {
        const res = await fetch(`${baseUrl.url}/user/team/history`, {
          method: "Get",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setMyAffiliates(resData.data);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoader(false);
      }
    };
    getHistory();
  }, [token]);

  const handleSearch = async (e) => {
    e.preventDefault();

    if (myProfile.login_id > userId) {
      return toast.warn("you can not search this user");
    }

    if (!userId) {
      return;
    }
    try {
      const res = await fetch(`${baseUrl.url}/user/team/history/${userId}`, {
        method: "get",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        setSearchTeam(resData.data);
        toast.success(resData.message);
      } else {
        const resData = await res.json();
        toast.error(resData.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  console.log("search", searchTeam);
  if (loader) {
    return <Loader />;
  }

  return (
    <section className="py-10 px-4">
      <div className="card">
        <div className="flex justify-between mb-2">
          <p>My Affiliate</p>
          <Button label="Search Team" onClick={() => setVisible(true)} />
        </div>
        <DataTable value={[myAffiliates]} tableStyle={{ minWidth: "50rem" }}>
          <Column field="teamAChildCount" header="Team A"></Column>
          <Column field="teamBChildCount" header="Team B"></Column>
          <Column field="total_similer_matches" header="Total Match"></Column>
          <Column field="total_odd_matches" header="Total unmatched"></Column>
        </DataTable>
      </div>
      <Dialog
        header="Search Team"
        visible={visible}
        onHide={() => {
          setVisible(false);
          setSearchTeam("");
        }}
        style={{ width: "40vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="pt-5">
          <form onSubmit={handleSearch} className="grid grid-cols-12 gap-3">
            <div className="col-span-8">
              <span className="p-input-icon-left w-full">
                <i className="pi pi-search" />
                <InputText
                  placeholder="Search"
                  className="w-full"
                  onChange={(e) => setUserId(e.target.value)}
                />
              </span>
            </div>
            <Button label="Submit" type={"submit"} className="col-span-4" />
          </form>
          {searchTeam && (
            <div className="mt-3">
              <p className="text-2xl font-medium">Upline</p>
              <div>
                <p>Login ID: {searchTeam.user?.login_id}</p>
                <p>Name: {searchTeam.user?.name}</p>
                <p>Mobile: {searchTeam.user?.mobile}</p>
                <p>Email:{searchTeam.user?.email}</p>
                <p>Designation: {searchTeam.user_designation?.name}</p>
                <p>Total Match : {searchTeam.total_similer_matches}</p>
                <p>Total Un-Match : {searchTeam.total_odd_matches}</p>
              </div>

              <div className="mt-3">
                <p className="text-2xl font-medium">Team</p>
                <div className="grid grid-cols-2">
                  <div>
                    <p className="font-medium">Team A</p>
                    {searchTeam.team_a ? (
                      <div>
                        <p>Login ID: {searchTeam.team_a?.login_id}</p>
                        <p>Name: {searchTeam.team_a?.name}</p>
                        <p>Mobile: {searchTeam.team_a?.mobile}</p>
                        <p>Email: {searchTeam.team_a?.email}</p>
                        <p>
                          Designation: {searchTeam.team_a_designation?.name}
                        </p>
                      </div>
                    ) : (
                      <p> No Data Found in Team A</p>
                    )}
                  </div>
                  <div>
                    <p className="font-medium">Team B</p>
                    {searchTeam.team_b ? (
                      <div>
                        <p>Login ID: {searchTeam.team_b?.login_id}</p>
                        <p>Name: {searchTeam.team_b?.name}</p>
                        <p>Mobile: {searchTeam.team_b?.mobile}</p>
                        <p>Email: {searchTeam.team_b?.email}</p>
                        <p>
                          Designation: {searchTeam.team_b_designation?.name}
                        </p>
                      </div>
                    ) : (
                      <p>No Data Found In Team B </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </section>
  );
};

export default MyAffiliate;
